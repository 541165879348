import React from "react";
import { CircularProgress, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { ghostWhite, LOADER_BG_COLOR } from "../theme";

interface OwnProps {
  open: boolean;
  isFormLoad?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: (props: { isFormLoad: boolean | undefined })  => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 9999,
      inset: 0,
      backgroundColor: props.isFormLoad ? ghostWhite : LOADER_BG_COLOR,
      position: props.isFormLoad ? "absolute" : "fixed",
    }),
  })
);

export const spinnerTheme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        outline: "none !important",
      },
    },
  },
});

const Spinner = ({ open, isFormLoad }: OwnProps): JSX.Element => {
  const { modal } = useStyles({isFormLoad});
  return (
    <>
      {open && (
        <div className={modal}>
          <MuiThemeProvider theme={spinnerTheme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>
      )}
    </>
  );
};

Spinner.defaultProps = {
  open: false,
};

export default Spinner;
