import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { FilterParams } from "../../../types/filterTypes";
import { JuakaliUser } from "../../../redux/types";
import { getCurrentAssignee } from "../utils/dashboardHelper";
import { assigneeFilterTheme } from "../styles/toolbarStyles";
import { CLEAR, SELECT_OPTION } from "../../../constants";
import SearchableDropdown from "../../common/SearchableDropdown";

interface OwnProps {
  onFilterDataChange: (params: FilterParams) => void;
  assignee: string;
  users: JuakaliUser[];
}

class AssigneeFilter extends React.Component<OwnProps, any> {
  constructor(props: OwnProps) {
    super(props);
  }

  /**
   * @param event - targeted input event
   * @param value attribute gives currently selected value from searchable dropdown
   * @param reason attribute gives reason of triggered event
   */
  onChange = (_: object, value: any, reason: string) => {
    let { onFilterDataChange } = this.props;
    if (reason === SELECT_OPTION) {
      return onFilterDataChange({ assignee: value.id });
    } else if (reason === CLEAR) {
      return onFilterDataChange({ assignee: "" });
    }
  };

  /**
   * Function returns searchable dropdown filter (Assignee Filter)
   */
  render(): JSX.Element {
    let { users, assignee } = this.props;
    return (
      <MuiThemeProvider theme={assigneeFilterTheme}>
        <SearchableDropdown
          handleOnChange={this.onChange}
          value={(getCurrentAssignee(assignee, [...users]) as JuakaliUser | undefined | null) || null}
          options={[...users]}
          assignee={assignee}
        />
      </MuiThemeProvider>
    );
  }
}

export default AssigneeFilter;
