import React from "react";
import { cobalt, gainsboro, pureWhite } from "../theme";

const CollpaseIcon: React.FC = () => {
  return (
    <div className="icon-container">
      <svg width="40" height="40" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20.383" r="19" fill={pureWhite} stroke={cobalt} strokeWidth="2" className="menu-path" />
        <mask id="mask0_988_2336" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="25">
          <rect x="32" y="32.383" width="24" height="24" transform="rotate(180 32 32.383)" fill={gainsboro} />
        </mask>
        <g mask="url(#mask0_988_2336)">
          <path
            d="M26.3 15.0829C26.4833 15.2663 26.575 15.4996 26.575 15.7829C26.575 16.0663 26.4833 16.2996 26.3 16.4829L22.425 20.3829L26.3 24.2829C26.4833 24.4663 26.579 24.6956 26.587 24.9709C26.5957 25.2456 26.5 25.4829 26.3 25.6829C26.1167 25.8663 25.8833 25.9579 25.6 25.9579C25.3167 25.9579 25.0833 25.8663 24.9 25.6829L20.3 21.0829C20.2 20.9829 20.129 20.8746 20.087 20.7579C20.0457 20.6413 20.025 20.5163 20.025 20.3829C20.025 20.2496 20.0457 20.1246 20.087 20.0079C20.129 19.8913 20.2 19.7829 20.3 19.6829L24.9 15.0829C25.0833 14.8996 25.3127 14.8039 25.588 14.7959C25.8627 14.7873 26.1 14.8829 26.3 15.0829ZM19.7 15.0829C19.8833 15.2663 19.975 15.4996 19.975 15.7829C19.975 16.0663 19.8833 16.2996 19.7 16.4829L15.825 20.3829L19.7 24.2829C19.8833 24.4663 19.9793 24.6956 19.988 24.9709C19.996 25.2456 19.9 25.4829 19.7 25.6829C19.5167 25.8663 19.2833 25.9579 19 25.9579C18.7167 25.9579 18.4833 25.8663 18.3 25.6829L13.7 21.083C13.6 20.983 13.5293 20.8746 13.488 20.758C13.446 20.6413 13.425 20.5163 13.425 20.383C13.425 20.2496 13.446 20.1246 13.488 20.008C13.5293 19.8913 13.6 19.783 13.7 19.683L18.3 15.0829C18.4833 14.8996 18.7123 14.8039 18.987 14.7959C19.2623 14.7873 19.5 14.8829 19.7 15.0829Z"
            fill={cobalt}
          />
        </g>
      </svg>
    </div>
  );
};

export default CollpaseIcon;
