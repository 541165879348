import { createMuiTheme } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";

export const primaryColor = blue;

let theme = createMuiTheme({
  typography: {
    // useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "initial",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "initial",
        margin: "0 16px",
        minWidth: 0,
        [theme.breakpoints.up("md")]: {
          minWidth: 0,
        },
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    flex: 1,
    padding: "48px 36px 0",
    background: "#eaeff1",
  },
};
export const grey = "rgba(0, 0, 0, 0.54)";
export const rgbaLightGrey = "rgba(0, 0, 0, 0.25)";
export const transparentBlack = "rgba(0,0,0,0.2)";
export const transparentBlack1 = "rgba(0,0,0,0.14)";
export const transparentBlack2 = "rgba(0,0,0,0.12)";
export const lightGrey = "#EBEAE8";
export const toolTipGreyColor = "#E0E0E0";
export const cobalt = "#134A9F";
export const solitude = "#EAF2FF";
export const aviaryBlue = "#CCDCF6";
export const poloBlue = "#91aad2";
export const limeGreen = "#4CAF50";
export const paleBlue = "#D1E3FF";
export const strongRed = "#D00B0B";
export const lightRed = "#F2F2F2";
export const darkLiver = "#4F4F51";
export const white = "#FFFFFF";
export const primary = "#303F9F";
export const primaryMobile = "#386BF6";
export const black = "#000000";
export const cancelColor = "#DB3069";
export const graniteGray = "#575757";
export const blackishToolTipBg = "#575757";
export const greyIconBg = "#000000.5";
export const lightGreen = "#EFFFEF";
export const disabledButtonColor = "#a5badb";
export const osloGrey = "#868E96";
export const midNightBlue = "#2F4256";
export const gainsBoro = "#E2E2E2";
export const errorBackground = "#FFF4F4";
export const warningBackground = "#FFFCF6";
export const successBackground = "#F8FFF8";
export const orange = "#FF9800";
export const hoverShadowBlack = "#0000004d";
export const paleGrey = "#EFF0F3";
export const ghostWhite = "#F7F8FC";
export const turquoise = "#4EE4DB";
export const amethystPurple = "#B25BE7";
export const goldenrodYellow = "#F3CE5F";
export const coralOrange = "#EE7D63";
export const silverGray = "#BFC3CE";
export const steelBlue = "#7A98C6";
export const lightPink = "#FCEEF3";
export const snowWhite = "#FBFBFB";
export const blueGray = "#334151";
export const sliverGray = "#BFC3CE";
export const powderBlue = "#E5F0FF";
export const skyGray = "#96ACCE";
export const charcoalBlue = "#2F42561F";
export const inactiveColor = "#727C87";
export const lavenderBlue = "#E8EEFF";
export const darkCerise = "#BA2657";
export const pureWhite = "#FEFEFE";
export const gainsboro = "#D9D9D9";
export const deepBlue = "#29448B";
export const oceanBlue = "#3E8ED0";
export const iceBlue = "#60C1EF";
export const sapphireBlue = "#2853A6";
export const twilightBlue = "#253372";

export const NONE_MESSAGE_TEXT = "#000000";
export const NONE_MESSAGE_BACKGROUND = "#FFFFFF";
export const NONE_MESSAGE_BORDER = "#BFBFBF";

export const ERROR_MESSAGE_TEXT = "#D00B0B";
export const ERROR_MESSAGE_BACKGROUND = "#FDF5F5";
export const ERROR_MESSAGE_BORDER = "#D00B0B";

export const ALERT_MESSAGE_TEXT = "#FF9800";
export const ALERT_MESSAGE_BACKGROUND = "#FFFBF5";
export const ALERT_MESSAGE_BORDER = "#FF9800";

export const INFO_MESSAGE_TEXT = "#0042C2";
export const INFO_MESSAGE_BACKGROUND = "#F5F8FD";
export const INFO_MESSAGE_BORDER = "#0042C2";

export const SUCCESS_MESSAGE_FORM_TEXT = "green";
export const SUCCESS_MESSAGE_FORM_BACKGROUND = "#DFF0D8";
export const SUCCESS_MESSAGE_FORM_BORDER = "green";

export const PDF_ICON_COLOR = "#FF402F";
export const LOADER_BG_COLOR = "rgba(255,255,255,0.7)";
export const FORM_LOADER_BG_COLOR = "rgba(255,255,255,0.6)";
export const TRANSPARENT_BUTTON_BACKGROUND = "transparent";
export const NONE = "none";
export const lightBlackHover = "rgba(0, 0, 0, 0.05)";
export const ERROR_TABLE_BORDER = "1px solid #D00B0B";
export const SELECTED_TABLE_CELL = "1px solid #134a9f";
export const TABLE_BORDER = "1px solid rgba(185, 185, 185, 0.5)";
export const ERROR_BG_COLOR = "#D04B4B";
export const TOOLTIP_BACKGROUND_COLOR = "#5D6673";
export const ERROR_TOOLTIP_BOX_SHADOW = "0px 2px 2px rgba(0, 0, 0, 0.25)";

export default theme;

export const EditButtontheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "#EAF2FF",
        },
      },
      text: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
});
export const ButtonTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: "6px !important",
        "&:hover": { backgroundColor: "#CCDCF6 !important", opacity: "1 !important" },
      },
    },
    MuiTooltip: {
      tooltip: {
        color: "#ffffff",
        backgroundColor: "##575757",
        fontSize: "14px",
        fontWeight: 500,
        fontStyle: "normal",
        borderRadius: "4px",
      },
      tooltipPlacementBottom: { margin: "0px !important" },
    },
  },
});
export const ImageEditTheme = createMuiTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        overflowY: "hidden",
      },
    },
  },
});
export const zoomButtonTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: "6px !important",
        "&:hover": { backgroundColor: "#0000000D !important", opacity: "1 !important" },
        color: "#134A9F",
      },
    },
  },
});

export const errorMessageTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body1: {
        border: "2px solid rgba(213, 0, 0, 0.8) !important",
        backgroundColor: "rgba(208, 11, 11, 0.04)",
        color: "rgba(213, 0, 0, 0.8)",
        fontSize: "16px",
        padding: "16px",
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: "0.15",
        textAlign: "left",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0px 0px 16px 0px",
        borderRadius: "4px",
      },
    },
    MuiSvgIcon: {
      root: {
        fill: "rgba(213, 0, 0, 0.8) !important",
        marginLeft: "8px",
        cursor: "pointer",
      },
    },
  },
});

export const CommonToolTipTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: "6px !important",
        "&:hover": { backgroundColor: `${greyIconBg} !important`, opacity: "1 !important" },
      },
    },
    MuiTooltip: {
      tooltip: {
        color: white,
        backgroundColor: blackishToolTipBg,
        fontSize: "14px",
        fontWeight: 500,
        fontStyle: "normal",
      },
      tooltipPlacementBottom: { margin: "0px !important" },
    },
  },
});

export const accountIconColor = {
  A: "#4CAF50",
  B: "#EE7D63",
  C: "#F3CE5F",
  D: "#DB3069",
  E: "#B25BE7",
  F: "#3ABFB7",
  G: "#4CAF50",
  H: "#EE7D63",
  I: "#F3CE5F",
  J: "#DB3069",
  K: "#B25BE7",
  L: "#3ABFB7",
  M: "#4CAF50",
  N: "#EE7D63",
  O: "#F3CE5F",
  P: "#DB3069",
  Q: "#B25BE7",
  R: "#3ABFB7",
  S: "#4CAF50",
  T: "#EE7D63",
  U: "#F3CE5F",
  V: "#DB3069",
  W: "#B25BE7",
  X: "#3ABFB7",
  Y: "#4CAF50",
  Z: "#EE7D63",
  default: "#B25BE7",
};
