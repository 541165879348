import { useMediaQuery } from "@material-ui/core";
import { DEFAULT_ROWS_PER_PAGE, TABLEKEYS, MANUAL_INPUT, USE_DICTIONARY, STRING } from "../constants/index";
import { GrapesComponent, DropdownOption } from "../types/formtypes";
import { JUAKALI_LANGUAGE, Languages } from "../views/idm/IdmEnum";

export const getRowsPerPageSize = (tableKey: string): number => {
  const tableKeys = localStorage.getItem(TABLEKEYS);
  const rowsPerPageForTables = tableKeys && JSON.parse(tableKeys);
  const noOfRows = rowsPerPageForTables && rowsPerPageForTables[tableKey];
  return noOfRows || DEFAULT_ROWS_PER_PAGE;
};

export const setRowsPerPageSize = (tableKey: string, noOfRows: number): void => {
  const tableKeys = localStorage.getItem(TABLEKEYS);
  let rowsPerPageForTables = tableKeys ? JSON.parse(tableKeys) : {};
  rowsPerPageForTables = { ...rowsPerPageForTables, [tableKey]: noOfRows };
  localStorage.setItem(TABLEKEYS, JSON.stringify(rowsPerPageForTables));
};

export const checkNestedBox = (components: GrapesComponent[]) => {
  return components && Object.keys(components).length && components[0].type === "box" && components[0].tagName === "div" ? true : false;
};

export const getCurrentDropdownOption = (DropdownOptionList: DropdownOption[], currentValue: string): DropdownOption | undefined => {
  if (DropdownOptionList && DropdownOptionList.length) {
    return DropdownOptionList.find(DropdownData => DropdownData.value === currentValue);
  }
  return;
};

export const getFieldWidth = (fieldRef: any, isMobileView: boolean): string => {
  const fieldClasses: any[] | null = fieldRef && fieldRef.current && fieldRef.current.parentNode.classList;
  let showFullWidthOfColumn = true;
  !isMobileView &&
    fieldClasses &&
    fieldClasses.forEach((className: string) => {
      if (className === "MuiGrid-grid-md-12") showFullWidthOfColumn = false;
    });
  return `${showFullWidthOfColumn ? 100 : 50}%`;
};

export const useResponsiveQueries = () => {
  const currentLanguage = localStorage.getItem(JUAKALI_LANGUAGE);

  const isTabletQuery =
    currentLanguage === Languages.FRENCH.key || currentLanguage === Languages.SPANISH.key ? "(max-width: 1400px)" : "(max-width: 1270px)";

  const drawerCloseWidthQuery =
    currentLanguage === Languages.FRENCH.key || currentLanguage === Languages.SPANISH.key ? "(max-width: 1125px)" : "(max-width: 920px)";

  const isItTablet = useMediaQuery(isTabletQuery);
  const drawerCloseWidth = useMediaQuery(drawerCloseWidthQuery);

  return { isItTablet, drawerCloseWidth };
};
