import React from "react";

const IndividualIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", paddingRight: 10 }}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1015_723" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1015_723)">
          <path
            d="M12 11.9755C10.9 11.9755 10 11.6255 9.3 10.9255C8.6 10.2255 8.25 9.32553 8.25 8.22553C8.25 7.12553 8.6 6.22553 9.3 5.52553C10 4.82553 10.9 4.47552 12 4.47552C13.1 4.47552 14 4.82553 14.7 5.52553C15.4 6.22553 15.75 7.12553 15.75 8.22553C15.75 9.32553 15.4 10.2255 14.7 10.9255C14 11.6255 13.1 11.9755 12 11.9755ZM4 18.5005V17.6505C4 17.0172 4.15833 16.4755 4.475 16.0255C4.79167 15.5755 5.2 15.2339 5.7 15.0005C6.81667 14.5005 7.8875 14.1255 8.9125 13.8755C9.9375 13.6255 10.9667 13.5005 12 13.5005C13.0333 13.5005 14.0583 13.6297 15.075 13.888C16.0917 14.1464 17.1583 14.5172 18.275 15.0005C18.7917 15.2339 19.2083 15.5755 19.525 16.0255C19.8417 16.4755 20 17.0172 20 17.6505V18.5005C20 18.9172 19.8542 19.2714 19.5625 19.563C19.2708 19.8547 18.9167 20.0005 18.5 20.0005H5.5C5.08333 20.0005 4.72917 19.8547 4.4375 19.563C4.14583 19.2714 4 18.9172 4 18.5005Z"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

export default IndividualIcon;
