import { Paging } from "../../../redux/types";
import { KeyValuePair, QuickFilter, TaskQueryFilterParams } from "../../../types/taskQueryTypes";

/**
 * Function to get the results with padded zeros.
 * @param value input to be padded with leading zeros.
 * @param length input to get the desired length of output with padding  eg. paddingWithZero(5, 4) => 0005
 */
const paddingWithZero = (value: string, length: number): string => {
  value = typeof value === "number" || !isNaN(Number(value)) ? String(Number(value)) : value;
  let zeroPad = "";
  for (let zeros = 0; zeros < length; zeros++) {
    zeroPad += "0";
  }
  return value ? (value.length < length ? `${zeroPad.substring(0, length - value.length)}${value}` : value) : zeroPad;
};

/**
 * Function to get the desired date format
 * @param date input to be converted to specified date format based on seperator.
 * @param seperator input to specify the date format to be returned.
  `/` returns mm/dd/yyyy format
  `-` returns yyyy-mm-dd format
 */
export const getFormattedDate = (date: string | null | undefined, seperator: string): string | null => {
  if (!date) return null;
  let dateToBeFormatted = date.split(/[/-]+/);
  return seperator === "/"
    ? `${dateToBeFormatted[1]}/${dateToBeFormatted[0]}/${dateToBeFormatted[2]}`
    : seperator === "-"
    ? `${dateToBeFormatted[2]}-${dateToBeFormatted[1]}-${dateToBeFormatted[0]}`
    : `${dateToBeFormatted[2]}/${dateToBeFormatted[1]}/${dateToBeFormatted[0]}`;
};

/**
 * Function to get the total day of the month based on the year.
 * @param month month input for which month we need a days count.
 * @param year input for which year we need a month based days count.
 */
const daysInMonth = (month: string, year: string): number | string => {
  return new Date(parseInt(year), parseInt(month), 0).getDate();
};

/**
 * This function is used to validate the date field.
 * For a valid date the function will return the dd/mm/yyyy formatted date result.
 * For a invalid date input it will return the error codes based on the error types.
 * @param date Date Input
 * @param limitCheck Limit Check boolean flag is to check date/month/year limitations.
 */
export const validateDate = (date: string, limitCheck: boolean): { type: string; value?: string; dateLimit?: number | string } => {
  // replaces `-` in the date input
  date = date ? date.replace(/_+/g, "") : "";
  const dateValues = date.split("/");
  // return the error code `invalidDateFormat` when dd/mm/yyyy values not filled or non numbers.
  if (dateValues.length !== 3) {
    return { type: "invalidDateFormat" };
  }
  let dd = dateValues[0];
  let mm = dateValues[1];
  let yyyy = dateValues[2];
  let isInvalid = !mm || !dd || !yyyy || !Number(yyyy) || !Number(mm) || !Number(dd) ? true : false;
  if (isInvalid) {
    return { type: "invalidDateFormat" };
  }

  /**
   * return the any one of error code given below,
    `invalidMonth`, `invalidYear`, `invalidDate` when the given date is invalid.
   */
  if (limitCheck) {
    if (parseInt(mm) > 12 || parseInt(mm) === 0) return { type: "invalidMonth", value: mm };
    if (parseInt(yyyy) === 0 || parseInt(yyyy) > 2100) return { type: "invalidYear", value: yyyy };
    if (daysInMonth(mm, yyyy) < parseInt(dd) || parseInt(dd) === 0) return { type: "invalidDate", value: dd, dateLimit: daysInMonth(mm, yyyy) };
  }

  // padding with zero
  dd = paddingWithZero(dd, 2);
  mm = paddingWithZero(mm, 2);
  yyyy = paddingWithZero(yyyy, 4);
  return { type: "result", value: `${dd}/${mm}/${yyyy}` };
};

export const checkIfFilterParamsPresent = (params: TaskQueryFilterParams): boolean => {
  return Object.keys(params).length && Object.keys(params).find((parameter: string) => !!(params as KeyValuePair)[parameter]) ? true : false;
};

export function getTasksCount(filterType: QuickFilter, pagination: Paging) {
  const { totalAll, totalNew, totalToday, totalThisWeek, totalOverdue } = pagination || {};

  switch (filterType) {
    case QuickFilter.ALL:
      return totalAll;
    case QuickFilter.NEW:
      return totalNew;
    case QuickFilter.TODAY: 
      return totalToday;
    case QuickFilter.WEEK:
      return totalThisWeek;
    case QuickFilter.OVERDUE:
      return totalOverdue;
    default:
      return 0;
  }
}
