import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button, createMuiTheme, Grid } from "@material-ui/core";
import { customToolBarStyle } from "./objectDataStyles";
import { CustomToolbarProps } from "./reportTypes";
import { EMPTY, HTTP_METHOD, RECORDS, REPORT, TRUE } from "../../constants";
import { activeTabButtonStyle, reportStyles } from "./reportStyles";
import ReportsBloc, { defaultReportsState, ReportsState, updateReportsState } from "../../rxjs/reportsBloc";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { cancelColor, midNightBlue } from "../theme";
import { useBloc } from "../../rxjs/blocBuilder";
import RecordsIcon from "../common/RecordsIcon";
import AnalysesIcon from "../common/AnalysesIcon";
import { getPreliminaryCheckStatus } from "../../services/reportService";

function CustomToolBar(props: CustomToolbarProps): JSX.Element {
  const { t } = useTranslation();
  const reportsBloc = ReportsBloc.getInstance();
  const { objectDefinition } = props;
  const { externalAnalysisUrl } = objectDefinition;
  const formsState = useBloc<ReportsState>(defaultReportsState, reportsBloc.getSubject());
  const { currentTab, openDialog } = formsState.data || defaultReportsState;
  const activeButtonClasses = activeTabButtonStyle();
  const { activeStyle, inactiveStyle } = reportStyles();
  const { GET, POST } = HTTP_METHOD;

  function getIconColor({ currentTab, taskType }: { currentTab: string; taskType: string }): string {
    return currentTab === taskType ? cancelColor : midNightBlue;
  }

  const handleTabChange = (val: string) => {
    updateReportsState({
      currentTab: val,
    });
  };
  const exportIconTheme = (createMuiTheme as any)({
    overrides: {
      MuiIconButton: {
        root: {
          padding: "8px !important",
        },
      },
    },
  });

  const handleOnRecordButtonClick = async () => {
    const result = await getPreliminaryCheckStatus(GET);
    result ? handleTabChange(REPORT) : updateReportsState({ openDialog: true });
  };

  const onConfirmHandler = async () => {
    const result = await getPreliminaryCheckStatus(POST);
    if (result) {
      handleTabChange(REPORT);
      updateReportsState({ openDialog: false });
    }
  };

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        style={currentTab == REPORT ? customToolBarStyle.reportTabStyle : {}}
        className="buttonScrollContainer"
      >
        <Grid item style={customToolBarStyle.buttonContainer}>
          <Button
            data-testid="recordsTabButton"
            variant="outlined"
            className={`recordsTabButton ${activeButtonClasses.commonStyle}`}
            style={currentTab === RECORDS ? activeStyle : inactiveStyle}
            onClick={() => handleTabChange(RECORDS)}
          >
            <RecordsIcon color={getIconColor({ currentTab, taskType: RECORDS })} />
            {t("reports.records")}
          </Button>
        </Grid>
        <Grid item>
          {externalAnalysisUrl && (
            <Button
              data-testid="reportTabButton"
              variant="outlined"
              disabled={!externalAnalysisUrl}
              className={externalAnalysisUrl ? activeButtonClasses.commonStyle : activeButtonClasses.disabledButtonStyle}
              style={currentTab === REPORT ? activeStyle : inactiveStyle}
              onClick={handleOnRecordButtonClick}
            >
              <AnalysesIcon color={getIconColor({ currentTab, taskType: REPORT })} />
              {t("reports.analyses")}
            </Button>
          )}
        </Grid>
        <ConfirmationDialog
          open={openDialog}
          content={EMPTY}
          warningContent={t("reports.reportAnalysisDialogText")}
          title={t("reports.reportAnalysisDialogTitle")}
          primaryLabel={t("common.continue")}
          secondaryLabel={EMPTY}
          primaryHandler={onConfirmHandler}
          alignActionsOnRightSide
        />
      </Grid>
    </Fragment>
  );
}

export default CustomToolBar;
