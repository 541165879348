import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import fetch from "isomorphic-fetch";
import { withTranslation } from "react-i18next";

import Button from "@material/react-button";
import { Grid, Row, Cell } from "@material/react-layout-grid";
import { Headline2 } from "@material/react-typography";

import { TextField, InputAdornment, FormHelperText, CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import "./Login.scss";
import { AuthenticationStatus, LOGIN, LOGIN_FAILED, LOGIN_SUCCESS } from "./IdmEnum";
import { url } from "../../services/apiConfig";
import { STATUS_CODES } from "../../constants";

import Log from "../../core/Log";
import { tenantId } from "../../core/tenantIdentifier";
import { fetchWrapper } from "../../core/fetchWrapper";
import { MuiThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";
import LoginBloc, { updateLogInBlocState } from "../../rxjs/loginBloc";
import LanguageSelector from "../common/LanguageSelector";

const styles = {
  helperText: {
    fontSize: "small",
    lineHeight: "1.6",
    margin: "3px 12px 0",
  },
  helperStyle: { fontSize: "small", lineHeight: "1.5", margin: 0 },
};

const myTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      error: {
        borderColor: "red !important",
      },
    },
  },
});

const { ERROR, FAILED, REQUIRE_PASSWORD_CHANGE, SUCCESS } = AuthenticationStatus;
const { BAD_REQUEST, SUCCESS: OK, TEMPORARY_REDIRECT } = STATUS_CODES;

class Login extends Component {
  constructor(props) {
    super(props);
    /* get session status from rxjs store */
    const loginStateBloc = LoginBloc.getInstance();
    let loginState = loginStateBloc.getSubject();
    let { sessionOut, serverError, isAccount, isRedirect, currentPath } = loginState.value;
    this.state = {
      username: "",
      password: "",
      disableLogin: false,
      showPassword: false,
      currentPath: currentPath,
      error: sessionOut && (isAccount || isRedirect) ? "sessionExpired" : serverError ? "errorLogginIn" : "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.updateAuthStatus = this.updateAuthStatus.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
  }
  // componentDidMount() {
  //   const { username, password } = this.state;
  //   if (username && password) {
  //     this.setState({ disableLogin: false });
  //   }
  // }
  authenticateFunction = () => {
    var authenticationRequest = { email: this.state.username, password: this.state.password, withCookie: true, channel: "Web" };
    return fetch(url.authentication, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-tenant-name": tenantId(),
      },
      credentials: "same-origin",
      body: JSON.stringify(authenticationRequest),
    });
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      // },
      // () => {
      //   if (this.state.username && this.state.password) {
      //     this.setState({ disableLogin: false });
      //   } else {
      //     this.setState({ disableLogin: true });
      //   }
    });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  updateAuthStatus(action) {
    switch (action.type) {
      case SUCCESS:
        ReactGA.event({
          category: LOGIN,
          action: LOGIN_SUCCESS,
          label: LOGIN_SUCCESS,
        });
        if (this.state.currentPath) {
          this.props.history.push(this.state.currentPath);
        } else {
          this.props.history.push("/");
        }
        break;
      case REQUIRE_PASSWORD_CHANGE:
        /* update rxjs store values */
        updateLogInBlocState({ isRedirect: true });
        this.props.history.push(url.changePassword);
        break;
      case FAILED:
        ReactGA.event({
          category: LOGIN,
          action: LOGIN_FAILED,
          label: LOGIN_FAILED,
        });
        this.setState({ error: "wrongCredentials" });
        // showNotification("warning", t("login.wrongCredentials"));
        break;
      case ERROR:
        ReactGA.event({
          category: LOGIN,
          action: LOGIN_FAILED,
          label: LOGIN_FAILED,
        });
        // showNotification("error", t("login.errorLogginIn"));
        this.setState({ error: "errorLogginIn" });
        break;
      default:
        break;
    }
  }

  login() {
    let { username, password } = this.state;
    var re = /\S+@\S+\.\S+/;
    let emailError = !username || !(username && username.trim()) ? "requiredEmail" : !re.test(username) ? "invalidEmail" : "";
    let passwordError = !password || !(password && password.trim()) ? "requiredPassword" : "";
    // first set the state back to pending
    if (!emailError && !passwordError) {
      this.setState(
        {
          disableLogin: true,
          emailError: emailError,
          passwordError: passwordError,
          error: "",
        },
        () => {
          // now try to log in
          fetchWrapper(this.authenticateFunction).then(
            res => {
              this.setState({
                disableLogin: false,
              });
              const { status } = res;
              if (status === OK) {
                this.updateAuthStatus({ type: SUCCESS });
              } else if (status === TEMPORARY_REDIRECT) {
                this.updateAuthStatus({ type: REQUIRE_PASSWORD_CHANGE });
              } else if (status === BAD_REQUEST) {
                this.updateAuthStatus({ type: FAILED });
              } else {
                this.updateAuthStatus({ type: ERROR });
              }
            },
            error => {
              this.setState({
                disableLogin: false,
              });
              console.log("in Error");
              this.updateAuthStatus({ type: ERROR });
              Log.error(error);
            }
          );
        }
      );
    } else {
      this.setState({
        emailError: emailError,
        passwordError: passwordError,
        error: "",
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { emailError, passwordError, error, disableLogin } = this.state;

    const desktopColumns = 12;
    const tabletColumns = 8;
    const phoneColumns = 4;

    return (
      <MuiThemeProvider theme={myTheme}>
        <form action="">
          <div>
            <div className="mdc-top-app-bar--fixed-adjust">
              <Grid className="login-grid">
                <Row>
                  <Cell desktopColumns={desktopColumns} tabletColumns={tabletColumns} phoneColumns={phoneColumns} align="middle">
                    <Headline2>{t("login.logIn")}</Headline2>
                  </Cell>
                </Row>
                <Row>
                  <Row>
                    <Cell desktopColumns={desktopColumns} tabletColumns={tabletColumns} phoneColumns={phoneColumns} align="middle">
                      {error && (
                        <FormHelperText className="fieldWidth" error={!!error} style={styles.helperStyle}>
                          {t(`login.${error}`)}
                        </FormHelperText>
                      )}
                    </Cell>
                  </Row>
                  <Cell desktopColumns={desktopColumns} tabletColumns={tabletColumns} phoneColumns={phoneColumns} align="middle">
                    <TextField
                      variant="outlined"
                      label={t("login.email")}
                      error={emailError || error === "wrongCredentials"}
                      // autoComplete="nope"
                      value={this.state.username}
                      id="username"
                      name="username"
                      onChange={this.handleChange}
                      autoFocus={true}
                      FormHelperTextProps={{ classes: { root: classes.helperText } }}
                      onBlur={() => {
                        this.setState({
                          emailError: "",
                          // passwordError: "",
                        });
                      }}
                      helperText={emailError && t(`login.${emailError}`)}
                      className="fieldWidth"
                    />
                  </Cell>
                  <Cell desktopColumns={desktopColumns} tabletColumns={tabletColumns} phoneColumns={phoneColumns} align="middle">
                    <TextField
                      variant="outlined"
                      label={t("login.password")}
                      value={this.state.password}
                      // autoComplete="nope"
                      id="password"
                      name="password"
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handleChange}
                      className="fieldWidth"
                      error={passwordError || error === "wrongCredentials"}
                      helperText={passwordError && t(`login.${passwordError}`)}
                      FormHelperTextProps={{ classes: { root: classes.helperText } }}
                      onBlur={() => {
                        this.setState({
                          // emailError: "",
                          passwordError: "",
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={this.handleClickShowPassword}>
                              {!this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        // autoComplete: "new-password",
                      }}
                    />
                  </Cell>
                  <Cell desktopColumns={desktopColumns} tabletColumns={tabletColumns} phoneColumns={phoneColumns} align="middle">
                    {disableLogin ? (
                      // <div className="fieldWidth" style={{ textAlign: "center" }}>
                      <CircularProgress />
                    ) : (
                      // </div>
                      <Button
                        type="submit"
                        onClick={e => {
                          e.preventDefault();
                          return this.login();
                        }}
                        raised
                      >
                        {t("login.loginBtn")}
                      </Button>
                    )}
                  </Cell>
                  <Cell desktopColumns={desktopColumns} tabletColumns={tabletColumns} phoneColumns={phoneColumns} align="middle">
                    <LanguageSelector />
                  </Cell>
                </Row>
              </Grid>
            </div>
          </div>
        </form>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(withStyles(styles)(withTranslation()(Login)));
