import React from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { GrapesJSFormController, StoreCreationService } from "@juakali/juakali-forms-react";
import GrapesForm from "../tasks/GrapesForm";
import { CombinedUserTaskConfiguration, CombinedUserTaskContainer, FormItem, FormList, FormWrapper, Header } from "../tasks/combinedusertask";
import { fields } from "../tasks/fields";
import { FORM_DESIGNER_PATH, GRAPES_JS_FORM } from "./constants";
import { readOnlyTaskStyles } from "./styles";
import { TaskViewRepresentation } from "../../types/taskQueryTypes";
import { GrapesForm as GS } from "../../types/formtypes";
import { SystemDictionary, Variables } from "../../redux/types";
import { LaunchRounded } from "@material-ui/icons";
import { checkForAccessTestRole } from "../tasks/GrapesForm/FormFields/helper";
import { getRedirectBaseUrl } from "./utils";
import HeaderBase from "./HeaderBase";

export interface ReadOnlyFormSectionProps {
  formType?: string;
  processInstance?: any;
  assigneeComponent?: any;
  handleGoBackOnTeamView?: any;
  processName?: any;
  taskDetails?: any;
  teamTask?: TaskViewRepresentation;
  formData: Partial<CombinedUserTaskConfiguration & GS>;
  dictionaries?: SystemDictionary[];
  variables?: Variables | undefined;
  isReport?: boolean;
}

export default function ReadOnlyFormSection(props: ReadOnlyFormSectionProps): JSX.Element {
  let {
    formType,
    teamTask,
    formData,
    dictionaries,
    variables,
    assigneeComponent,
    taskDetails,
    processInstance,
    processName,
    handleGoBackOnTeamView,
    isReport,
  } = props;
  let { paperElevation, typographyH5, headerPadding, headerTitleContainer, redirectIcon } = readOnlyTaskStyles;
  let { processVariables, taskVariables } = variables || {
    processVariables: {},
    taskVariables: {},
  };
  let mergedVariables = { ...processVariables, ...taskVariables };
  const hasAccessTestRole = checkForAccessTestRole();
  const headerProps = !isReport
    ? { assigneeComponent, processInstance, taskDetails, processName, handleGoBackOnTeamView, isReport }
    : { handleGoBackOnTeamView, isReport, formData };

  const redirectToFromDesigner = (baseUrl: string): void => {
    window.open(`${baseUrl}/${FORM_DESIGNER_PATH}/${formData.id}`);
  };

  return (
    <Paper square={true} variant="outlined" style={paperElevation}>
      {formType === GRAPES_JS_FORM ? (
        <Grid style={headerPadding}>
          <Grid>
            <HeaderBase {...headerProps} />
          </Grid>
          <Grid style={headerTitleContainer}>
            <Typography variant="h5" component="h5">
              {formData.name}
            </Typography>
            {hasAccessTestRole && isReport && <LaunchRounded onClick={() => redirectToFromDesigner(getRedirectBaseUrl())} style={redirectIcon} />}
          </Grid>
          <GrapesJSFormController
            task={teamTask}
            taskId={""}
            processInstance={{}}
            processVariables={processVariables}
            variables={mergedVariables}
            formData={formData}
            showOutcomes={false}
            dictionaries={dictionaries}
            elements={StoreCreationService.createStoresForFormComponents(
              formData ? formData.modelJson : [],
              mergedVariables,
              dictionaries,
              {},
              processVariables,
              true,
              true
            )}
            submitVariablesOnSave={true}
            isItACombinedUserTask={false}
            submitOnSave={false}
            fields={fields}
            disableValidation={true}
          />
        </Grid>
      ) : (
        <CombinedUserTaskContainer
          combinedUserTaskModelData={formData}
          combinedUserTaskUIComponents={{
            FormWrapper: FormWrapper,
            FormItem: FormItem,
            FormList: FormList,
            Header: Header,
            GrapesForm: GrapesForm,
            AssigneeComponent: assigneeComponent,
          }}
          handleGoBackOnTeamView={handleGoBackOnTeamView}
          processInstance={processInstance}
          processVariables={processVariables}
          variables={mergedVariables}
          dictionaries={dictionaries}
          task={teamTask}
          taskId={""}
          showOutcomes={false}
          submitVariablesOnSave={true}
          submitOnSave={true}
          fields={fields}
          isReport={isReport}
        ></CombinedUserTaskContainer>
      )}
    </Paper>
  );
}
