export const DELETE = "DELETE";
export const GRAPES_FORM = "GRAPES_FORM";
export const CUT_FORM = "CUT";
export const OTHERS = "Others";
export const DICTIONARY = "dictionary";
export const TASK_DETAILS = "taskDetails";
export const OPEN_TASKS = "openTasks";
export const FORM_DATA = "formData";
export const FORM_MODEL = "formModel";
export const PROCESS_INSTANCE_DETAIL = "processInstanceDetail";
export const GET_VARS = "getVariables";
export const standardImageQuality = "standard";
export const goodImageQuality = "Good";
export const highImageQuality = "high";
export const KB_BYTES_DIV = 1024;
export const STANDARD_OPTION = 80;
export const GOOD_OPTION = 300;
export const HIGH_OPTION = 500;
export const EIGHT = 8;
export const SLICE_SIZE = 1024;
export const MIN_ZOOM_LEVEL = 0.5;
export const MAX_ZOOM_LEVEL = 2;
export const CAMERA_AND_GALLREY = "cameraAndGallery";
export const CAMERA = "camera";
export const defaultQuality = "good";
export const NEW = "NEW";
export const TOOLTIP_PLACEMENT = "bottom-start";
export const MY_TASKS = "myTasks";
export const CANDIDATE_TASKS = "candidateTask";
export const TEAM_TASKS = "teamTasks";
export const RECORDS = "records";
export const BOTTOM_PLACEMENT = "bottom";
