import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { EMPTY } from "../../../constants";
import { dashboardTableTheme } from "../../common/theme";

export default function DataTable({ key, columns, data, options, innerRef }: any) {
  return (
    <div className="dashboardDataTableContainer">
      <MuiThemeProvider theme={dashboardTableTheme}>
        <MUIDataTable title={EMPTY} key={key} columns={columns} data={data} options={options} innerRef={innerRef} />
      </MuiThemeProvider>
    </div>
  );
}
