import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import HeaderBase from "../../../TeamTask/HeaderBase";
import { formHeaderStyles } from "../styles";

function HeaderView(props: any) {
  const { clearVariablesFromStores, cutStore, getCurrentForm, onGoBack, props: cutProps, getTitle, variables } = props;
  const { task, processInstance, assigneeComponent, handleGoBackOnTeamView, title, isReport } = cutProps;
  const { t } = useTranslation(["translation", "flow"]);
  const processName = processInstance && processInstance.processDefinitionName;
  const { headerViewStyle } = formHeaderStyles();
  const { processVariables } = props.cutStore;
  return (
    <Grid container>
      <Grid item xs={12} md={8} lg={8}>
        <Grid className={headerViewStyle}>
          <HeaderBase
            clearVariablesFromStores={clearVariablesFromStores}
            onGoBack={onGoBack}
            headerTitle={getTitle()}
            processName={processInstance.name}
            assigneeComponent={assigneeComponent}
            handleGoBackOnTeamView={handleGoBackOnTeamView}
            cutStore={cutStore}
            getCurrentForm={getCurrentForm}
            title={title}
            taskDetails={{
              ...task,
              processDefinitionName: processName,
              assigneeName: task && task.assignee ? task.assignee.firstName : t("flow:TASK.MESSAGE.NO-ASSIGNEE"),
            }}
            variables={cutStore.currentVariables}
            processVariables={processVariables}
            isReport={isReport}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(HeaderView);
