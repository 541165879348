import React, { useContext } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import LogoutButton from "./LogoutButton";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { logoutStyles, logoutViewStyle, popOverStyles } from "../styles";
import { AccountContext } from "../../../App";
import { Divider } from "@material-ui/core";
import { getColor } from "../../Dashboard/utils/dashboardHelper";
import { EMPTY } from "../../../constants";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function Logout(props: any) {
  const classes: any = logoutStyles();
  const { open, handleClose, handleLogout, mobileView } = props;

  const accountContext = useContext(AccountContext);

  const popOverStyle = popOverStyles();
  let name = accountContext ? accountContext.fullName || accountContext.email : EMPTY;
  const { accountIcon } = logoutViewStyle(mobileView);
  return (
    <Popover
      id="open"
      open={open}
      onClose={handleClose}
      className={classes.popup}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{
        root: popOverStyle.popoverRoot,
        paper: popOverStyle.paper,
      }}
    >
      <div className={classes.logoutPaperEmail}>
        <AccountCircleOutlinedIcon htmlColor={getColor(name)} style={accountIcon} />
        <div aria-label="userInfo" className={classes.userInfo}>
          <Typography variant="overline" className={classes.userInfoTextStyle} color="primary" display="block">
            {accountContext ? accountContext.fullName : ""}
          </Typography>
          <Typography variant="overline" className={classes.userEmailStyle} color="primary" display="block">
            {accountContext ? accountContext.email : ""}
          </Typography>
        </div>
      </div>
      <div className={classes.logoutMain}>
        <Divider orientation="horizontal" />
        <div className={classes.logoutPaperBtn}>
          <LogoutButton handleLogout={handleLogout} />
        </div>
      </div>
    </Popover>
  );
}

export default Logout;
