import React from "react";
import { cobalt, gainsboro, pureWhite } from "../theme";

const HamburgerMenuIcon = () => {
  return (
    <div className="icon-container hamburger">
      <svg width="40" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="hamburgerIcon">
        <circle
          cx="20.7246"
          cy="20"
          r="19"
          transform="rotate(-180 20.7246 20)"
          fill={pureWhite}
          stroke={cobalt}
          stroke-width="2"
          className="menu-path"
        />
        <mask id="mask0_750_13014" maskUnits="userSpaceOnUse" x="8" y="8" width="25" height="24">
          <rect x="8.72461" y="8" width="24" height="24" fill={gainsboro} />
        </mask>
        <g mask="url(#mask0_750_13014)">
          <path
            d="M12.7246 26C12.4413 26 12.2038 25.9042 12.0121 25.7125C11.8204 25.5208 11.7246 25.2833 11.7246 25C11.7246 24.7167 11.8204 24.4792 12.0121 24.2875C12.2038 24.0958 12.4413 24 12.7246 24H28.7246C29.0079 24 29.2454 24.0958 29.4371 24.2875C29.6288 24.4792 29.7246 24.7167 29.7246 25C29.7246 25.2833 29.6288 25.5208 29.4371 25.7125C29.2454 25.9042 29.0079 26 28.7246 26H12.7246ZM12.7246 21C12.4413 21 12.2038 20.9042 12.0121 20.7125C11.8204 20.5208 11.7246 20.2833 11.7246 20C11.7246 19.7167 11.8204 19.4792 12.0121 19.2875C12.2038 19.0958 12.4413 19 12.7246 19H28.7246C29.0079 19 29.2454 19.0958 29.4371 19.2875C29.6288 19.4792 29.7246 19.7167 29.7246 20C29.7246 20.2833 29.6288 20.5208 29.4371 20.7125C29.2454 20.9042 29.0079 21 28.7246 21H12.7246ZM12.7246 16C12.4413 16 12.2038 15.9042 12.0121 15.7125C11.8204 15.5208 11.7246 15.2833 11.7246 15C11.7246 14.7167 11.8204 14.4792 12.0121 14.2875C12.2038 14.0958 12.4413 14 12.7246 14H28.7246C29.0079 14 29.2454 14.0958 29.4371 14.2875C29.6288 14.4792 29.7246 14.7167 29.7246 15C29.7246 15.2833 29.6288 15.5208 29.4371 15.7125C29.2454 15.9042 29.0079 16 28.7246 16H12.7246Z"
            fill={cobalt}
          />
        </g>
      </svg>
    </div>
  );
};

export default HamburgerMenuIcon;
