import { Button, Grid, Switch, Typography, makeStyles, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { black, cobalt } from "../theme";
import i18n from "../../i18n";
import ReportFilterDialogBloc, {
  ReportFilterDialogState,
  defaultReportFilterDialogState,
  updateReportFilterDialogBlocState,
} from "../../rxjs/reportFilterDialogBloc";
import { useBloc } from "../../rxjs/blocBuilder";

interface OwnProps {
  applyFilter: (filterList: string[][]) => void;
  filterList: string[][];
  isExactSearch: boolean;
  t: i18n.TFunction;
}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

interface StyleProps {
  isExactSearch: boolean;
}

const useStyles = makeStyles(theme => ({
  exactSearchText: ({ isExactSearch }: StyleProps) => ({
    color: isExactSearch ? cobalt : black,
    fontWeight: isExactSearch ? 500 : 400,
  }),
  partialSearchText: ({ isExactSearch }: StyleProps) => ({
    fontWeight: !isExactSearch ? 500 : 400,
  }),
  maxWidth: {
    maxWidth: "fit-content",
  },
  margin: {
    marginTop: "12px",
    marginBottom: "12px",
  },
}));

const CustomToggleSwitch = withStyles({
  root: {
    width: "58px",
    height: "40px",
    display: "inline-block",
  },
  switchBase: {
    padding: "0px",
    color: cobalt,
    "&$checked": {
      color: `${cobalt} !important`,
    },
    "&$checked + $track": {
      backgroundColor: `${cobalt} !important`,
      opacity: 0.38,
    },
  },
  checked: {},
  track: {
    display: "block",
  },
})(Switch);

const CustomFilterDialogFooter = ({ applyFilter, filterList, isExactSearch: exactSearch, t }: OwnProps) => {
  const reportFilterDialogBloc = ReportFilterDialogBloc.getInstance();
  const state = useBloc<ReportFilterDialogState>({ isExactSearch: exactSearch }, reportFilterDialogBloc.getSubject());
  const { isExactSearch } = state && state.data ? state.data : defaultReportFilterDialogState;
  const { exactSearchText, partialSearchText, maxWidth, margin } = useStyles({ isExactSearch });

  const handleApplyFilter = () => applyFilter(filterList);

  const handleClick = () => updateReportFilterDialogBlocState({ isExactSearch: !isExactSearch });

  return (
    <Fragment>
      <Grid container alignItems="center" className={margin}>
        <Grid item xs={6} sm={4} className={maxWidth}>
          <Typography className={partialSearchText}>{t("reports.partialSearch")}</Typography>
        </Grid>
        <Grid item xs={6} sm={4} className={maxWidth}>
          <CustomToggleSwitch checked={isExactSearch} data-testid="switch" onClick={handleClick} />
        </Grid>
        <Grid item xs={12} sm={4} className={maxWidth}>
          <Typography className={exactSearchText}>{`${t("reports.exactSearch")} (${t("common.default")})`}</Typography>
        </Grid>
      </Grid>
      <Button className="confirm-button" data-testid="applyFilter" onClick={handleApplyFilter} variant="contained">
        {t("common.applyFilters")}
      </Button>
    </Fragment>
  );
};

export default withTranslation()(CustomFilterDialogFooter);
