import { BehaviorSubject } from "rxjs";
import { EMPTY, RECORDS } from "../constants";

export interface ReportsState {
  currentTab: string;
  isLoading: boolean;
  isSignedIn: boolean;
  googleAccount: string;
  openDialog: boolean;
}

export const defaultReportsState = {
  currentTab: RECORDS,
  isLoading: false,
  isSignedIn: false,
  googleAccount: EMPTY,
  openDialog: false,
};

export default class ReportsBloc {
  private static instance: ReportsBloc;
  private subject: BehaviorSubject<ReportsState>;

  private constructor() {
    this.subject = new BehaviorSubject(defaultReportsState);
  }

  static getInstance(): ReportsBloc {
    if (!ReportsBloc.instance) {
      ReportsBloc.instance = new ReportsBloc();
    }
    return ReportsBloc.instance;
  }

  getSubject = (): BehaviorSubject<ReportsState> => {
    return this.subject;
  };
  updateState = (value: Partial<ReportsState>): void => {
    this.subject.next({ ...this.subject.value, ...value });
  };
}

export function updateReportsState(value: Partial<ReportsState>): void {
  const reportsBloc = ReportsBloc.getInstance();
  reportsBloc.updateState(value);
}

export function getSubject(): ReportsState {
  const reportsBloc = ReportsBloc.getInstance();
  return reportsBloc.getSubject().getValue();
}
