import { createMuiTheme, makeStyles, Theme } from "@material-ui/core/styles";
import { textFieldColorTheme } from "./commonStyles";
import {
  aviaryBlue,
  charcoalBlue,
  cobalt,
  darkLiver,
  errorBackground,
  hoverShadowBlack,
  lavenderBlue,
  lightRed,
  limeGreen,
  midNightBlue,
  orange,
  paleGrey,
  powderBlue,
  skyGray,
  sliverGray,
  solitude,
  strongRed,
  successBackground,
  warningBackground,
  white,
} from "../../theme";
import { createStyles } from "@material-ui/styles";

const theme = createMuiTheme();

export const searchIconStyles = makeStyles({
  iconButton: {
    padding: 0,
  },
});

export const searchStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 40,
      border: `1px solid ${aviaryBlue}`,
      marginRight: 24,
      "&:hover": {
        borderWidth: 2,
        borderColor: cobalt,
      },
      "&$focused": {
        borderWidth: 2,
        borderColor: cobalt,
      },
    },
    input: {
      padding: 0,
      textOverflow: "ellipsis",
    },
    focused: {
      borderWidth: 2,
      borderColor: cobalt,
    },
    notchedOutline: {
      border: "none !important",
    },
    adornedStart: {
      paddingLeft: "8px",
    },
    adornedEnd: {
      paddingRight: "8px",
    },
  })
);

export const dateStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `2px solid ${sliverGray}`,
      "&:hover": {
        borderColor: cobalt,
      },
      "&$focused": {
        borderColor: cobalt,
      },
    },
    focused: {
      borderColor: cobalt,
    },
    notchedOutline: {
      border: "none !important",
    },
    optionStyle: { whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden" },
  })
);

export const dateFilterTheme = (createMuiTheme as any)({
  overrides: {
    MuiIconButton: {
      root: {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    MuiButton: {
      outlined: {
        padding: "8px 16px",
      },
      text: {
        padding: "8px 16px",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        paddingLeft: 0,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        paddingLeft: 0,
      },
    },
    MuiTypography: {
      body1: { fontSize: "20px", fontWeight: 500, lineHeight: "23px" },
      h6: { color: midNightBlue, fontFamily: "inter", fontWeight: 600, fontSize: 20, lineHeight: "28px" },
    },
    MuiDialogActions: {
      root: {
        flexDirection: "row-reverse",
        alignSelf: "flex-start",
        justifyContent: "unset",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: "4px 14px 0 0 !important",
        lineHeight: "140%",
      },
    },
    MuiPopover: {
      paper: {
        [theme.breakpoints.only("xs")]: {
          width: "280px",
        },
        [theme.breakpoints.up("sm")]: {
          width: "560px",
        },
        marginTop: "45px",
        maxWidth: "400px !important",
        borderRadius: "12px !important",
      },
    },

    MuiOutlinedInput: {
      notchedOutline: {
        border: `solid 2px ${sliverGray}`,
        borderColor: sliverGray,
      },
    },
    MuiChip: {
      root: { margin: 4, backgroundColor: powderBlue, fontFamily: "inter", fontWeight: 400, fontSize: 16, lineHeight: "24px" },
      deleteIcon: {
        color: skyGray,
        "&:hover": {
          backgroundColor: paleGrey,
        },
      },
      clickable: {
        "&:hover": {
          backgroundColor: powderBlue,
        },
      },
    },
  },
});

export const toolbarTheme = createMuiTheme({
  overrides: {
    MuiListItem: {
      gutters: {
        padding: "0 16px 8px 0 !important",
      },
      root: {
        alignItems: "flex-end",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
        margin: "0 0 -8px -8px",
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
      },
    },
    MuiListItemText: {
      root: {
        padding: 0,
        minWidth: 0,
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
        color: cobalt,
      },
    },
    MuiChip: {
      root: { margin: 4, backgroundColor: powderBlue, fontFamily: "inter", fontWeight: 400, fontSize: 16, lineHeight: "24px", letterSpacing: "1%" },
      deleteIcon: {
        color: skyGray,
        "&:hover": {
          color: cobalt,
          backgroundColor: paleGrey,
        },
      },
      clickable: {
        "&:hover": {
          backgroundColor: powderBlue,
        },
      },
    },
    MuiGrid: {
      "spacing-xs-10": {
        paddingBottom: "26px !important",
      },
      item: {
        paddingBottom: "0px !important",
        paddingTop: "0px !important",
      },
    },
  },
});

export const assigneeFilterTheme = (createMuiTheme as any)({
  overrides: {
    ...textFieldColorTheme,
    MuiAutocomplete: {
      root: {
        width: "100% !important",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: `solid 2px ${sliverGray}`,
        borderColor: sliverGray,
      },
    },
    MuiChip: {
      root: { margin: 4, backgroundColor: powderBlue, fontFamily: "inter", fontWeight: 400, fontSize: 16, lineHeight: "24px" },
      deleteIcon: {
        color: skyGray,
        "&:hover": {
          backgroundColor: paleGrey,
        },
      },
      clickable: {
        "&:hover": {
          backgroundColor: powderBlue,
        },
      },
    },
    MuiGrid: {
      "spacing-xs-10": {
        paddingBottom: "26px !important",
        marginTop: "26px !important",
      },
      item: {
        paddingBottom: "0px !important",
        paddingTop: "0px !important",
      },
    },
  },
});

export const customFilterStyles = (isMobileView: boolean) => {
  return {
    actions: {
      display: "flex",
      justifyContent: "end",
      backgroundColor: white,
      boxShadow: `0px -2px 12px 0px ${charcoalBlue}`,
      paddingTop: 12,
      paddingRight: 24,
      paddingBottom: 12,
    },
    assigneeBody: { padding: "24px 16px 16px 24px", display: "flex", borderBottom: `1px solid ${sliverGray}` },
    contentBody: {
      display: "flex",
      gap: 16,
      padding: "24px 24px 38px 24px",
    },
  };
};

export const defaultToolbarStyles = {
  filterButtons: {
    margin: "8px 0 16px",
  },

  quickFilterRoot: {
    "& button": {
      fontSize: "13.125px",
      fontWeight: 600,
      padding: "8px 10px",
      lineHeight: "19px",
      color: darkLiver,
      borderRadius: "5px",

      "&.active, &:hover": {
        boxShadow: `0px 3px 6px ${hoverShadowBlack}`,
      },
    },
  },
  ALL: {
    "&.active, &:hover": {
      border: `1px solid ${darkLiver}`,
      background: lightRed,
    },
  },
  NEW: {
    "&.active, &:hover": {
      color: cobalt,
      border: `1px solid ${cobalt}`,
      background: solitude,
    },
  },
  TODAY: {
    "&.active, &:hover": {
      border: `1px solid ${limeGreen}`,
      background: successBackground,
      color: limeGreen,
    },
  },
  WEEK: {
    "&.active, &:hover": {
      border: `1px solid ${orange}`,
      background: warningBackground,
      color: orange,
    },
  },
  OVERDUE: {
    "&.active, &:hover": {
      border: `1px solid ${strongRed}`,
      background: errorBackground,
      color: strongRed,
    },
  },
};

export const filterButtontheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        minHeight: 40,
        maxWidth: 40,
        padding: "7px",
        borderColor: aviaryBlue,
        "&:hover": {
          backgroundColor: `${lavenderBlue} !important`,
          border: `1.4px solid ${cobalt} !important`,
        },
        "&:active": {
          backgroundColor: `${lavenderBlue} !important`,
          border: `1.4px solid ${cobalt} !important`,
        },
      },
    },
  },
});
