// @flow
import fetch from "isomorphic-fetch";
import { url } from "./apiConfig";
import { DownloadUserRequest, DynamicJson, ReassignParams } from "../redux/types";
import {
  TaskQueryParams,
  QueryParams,
  NotificationParam,
  TaskAssignment,
  sortTypeAndColumnMapper,
  SortType,
  TaskTableColumn,
  MultipleReassignTaskParam,
  QuickFilter,
} from "../types/taskQueryTypes";
import { EMPTY, HTTP_METHOD } from "../constants/index";

const { GET, PATCH, POST, PUT } = HTTP_METHOD;
const {
  file,
  queryNotifications,
  queryTaskDetail,
  queryTaskForm,
  queryTasks,
  reassignTask,
  taskNotifications,
  users,
  webhook,
  bulkReassignTask,
  tasks,
} = url;

export function fetchForQueryAndSortTasks(params?: TaskQueryParams): Promise<Response> {
  const isAssignedTask = !!(params && params.assignment === TaskAssignment.INVOLVED);
  const customizedParams = isAssignedTask ? customParamsForAssignedTask(params) : customParamsForCandidateTask(params);
  return fetch(`${tasks}/${isAssignedTask ? "assigned" : "candidate"}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: POST,
    body: JSON.stringify({
      ...customizedParams,
    }),
  });
}

type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

interface QueryWithCustomTeamParams extends Omit<QueryParams, "sortType"> {
  sortParams?: [
    {
      param: string;
      order: string;
    }
  ];
  sortType?: string;
}

const customizeQueryParams = (params: QueryWithCustomTeamParams): QueryWithCustomTeamParams => {
  if (params.sortType) {
    switch (params.sortType) {
      case "DUE_DATE_ASCENDING":
        params.sortParams = [{ param: "dueDate", order: "ASC" }];
        break;
      case "DUE_DATE_DESCENDING":
        params.sortParams = [{ param: "dueDate", order: "DESC" }];
        break;
      case "TASK_NAME_ASCENDING":
        params.sortParams = [{ param: "name", order: "ASC" }];
        break;
      case "TASK_NAME_DESCENDING":
        params.sortParams = [{ param: "name", order: "DESC" }];
        break;
      case "ASSIGNEE_ASCENDING":
        params.sortParams = [{ param: "iu.displayName", order: "ASC" }];
        break;
      case "ASSIGNEE_DESCENDING":
        params.sortParams = [{ param: "iu.displayName", order: "DESC" }];
        break;
      case "PROCESS_NAME_ASCENDING":
        params.sortParams = [{ param: "pd.name", order: "ASC" }];
        break;
      case "PROCESS_NAME_DESCENDING":
        params.sortParams = [{ param: "pd.name", order: "DESC" }];
        break;
    }
    delete params.sortType;
  }

  applyQuickFilterParam(params);

  return params;
};

function applyQuickFilterParam(params: any): void {
  params.countAvailability = true;

  if (params.quickFilterType) {
    const { quickFilterType, filterParams } = params;
    let quickFilterParams: DynamicJson = {
      status: undefined,
      dateType: undefined,
    };

    switch (quickFilterType) {
      case QuickFilter.NEW:
        quickFilterParams.status = quickFilterType;
        break;
      case QuickFilter.TODAY:
        quickFilterParams.dateType = quickFilterType;
        break;
      case QuickFilter.WEEK:
        quickFilterParams.dateType = quickFilterType;
        break;
      case QuickFilter.OVERDUE:
        quickFilterParams.dateType = quickFilterType;
        break;
    }

    params.filterParams = { ...filterParams, ...quickFilterParams };

    delete params.quickFilterType;
  }
}

export function fetchForQueryAndSortTeamTasks(params?: QueryParams): Promise<Response> | void {
  if (params) {
    let customizedParams = customizeQueryParams(params);
    return fetch(`${tasks}/team`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: POST,
      body: JSON.stringify({
        ...customizedParams,
      }),
    });
  }
}

export function fetchForOpenProcessTasks(processInstanceId: string): Promise<Response> {
  return fetch(queryTasks, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: POST,
    body: JSON.stringify({
      processInstanceId,
    }),
  });
}

export function fetchForCompletedProcessTasks(processInstanceId: string): Promise<Response> {
  return fetch(queryTasks, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: POST,
    body: JSON.stringify({
      processInstanceId,
      state: "completed",
    }),
  });
}

export function fetchForCompletedTasksByProcessInstanceId(processInstanceId: string): Promise<Response> {
  return fetch(queryTasks, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: POST,
    body: JSON.stringify({
      processInstanceId,
      state: "completed",
    }),
  });
}

export function fetchForTaskForm(taskId: string): Promise<Response> {
  return fetch(queryTaskForm + taskId, {
    method: GET,
  });
}

export function fetchForTaskDetail(taskId: string): Promise<Response> {
  return fetch(queryTaskDetail + taskId, {
    method: GET,
  });
}

export function canClaimTask(task: any, account: any): boolean {
  if (!account) {
    return false;
  }

  if (!task) {
    return false;
  }

  if (
    (task.assignee == null || task.assignee === undefined) &&
    (task.memberOfCandidateGroup ||
      task.memberOfCandidateUsers ||
      (task.initiatorCanCompleteTask && task.processInstanceStartUserId === `${account.id}`))
  ) {
    return true;
  }
  return false;
}

export function ownsTask(task: any, account: any): boolean {
  if (!account) {
    return false;
  }

  if (!task) {
    return false;
  }

  if (!task.assignee) {
    return false;
  }

  if (task.assignee.id === account.id) {
    return true;
  }

  return false;
}

export function fetchAllUsers(downloadUserRequest: DownloadUserRequest): Promise<Response> {
  return fetch(users, {
    method: POST,
    body: JSON.stringify(downloadUserRequest),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function reassign(params: ReassignParams): Promise<Response> {
  return fetch(reassignTask, {
    method: PUT,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
}

export function fetchForWebhook(fetchData: any): Promise<Response> {
  return fetch(webhook, {
    method: POST,
    body: JSON.stringify(fetchData),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export const fetchForNotifications = (params?: NotificationParam): Promise<Response> => {
  const { userId } = params || { userId: EMPTY };
  return fetch(`${queryNotifications}/${userId}`, {
    method: GET,
  });
};

export const fetchForNotificationsRead = (notificationIds: String[]): Promise<Response> => {
  return fetch(`${taskNotifications}/read`, {
    method: PUT,
    body: JSON.stringify({ notificationIds }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const fetchForTaskStarted = (taskId: string): Promise<Response> => {
  return fetch(`${taskNotifications}/started/${taskId}`, {
    method: PUT,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const customParamsForAssignedTask = (params?: TaskQueryParams) => {
  if (!params) return params;
  const sortType = params.sortType || SortType.DUE_DATE_ASCENDING;
  const { columnName, sortDirection }: { columnName: string; sortDirection: string } = sortTypeAndColumnMapper[sortType];
  let sortColumnName = columnName;
  switch (columnName) {
    case TaskTableColumn.TASK_NAME:
      sortColumnName = "name";
      break;
    case TaskTableColumn.PROCESS_NAME:
      sortColumnName = "pd.name";
      break;
    default:
      break;
  }
  let customizedParams = { ...params };
  customizedParams.filterParams = { ...customizedParams.filterParams, status: params.filterNew ? "NEW" : null };
  customizedParams.sortParams = [{ param: sortColumnName, order: sortDirection.toUpperCase() }];

  applyQuickFilterParam(customizedParams);

  return customizedParams;
};

const customParamsForCandidateTask = (params?: TaskQueryParams) => {
  if (!params) return params;
  const sortType = params.sortType || SortType.DUE_DATE_ASCENDING;
  const { columnName, sortDirection }: { columnName: string; sortDirection: string } = sortTypeAndColumnMapper[sortType];
  const customizedParams = { ...params, sortParams: [{ param: columnName, order: sortDirection.toUpperCase() }] };
  
  applyQuickFilterParam(customizedParams);

  return customizedParams;
};

export function fetchToUploadFile(params: { formData: any; id?: string }): Promise<Response> {
  const { formData, id } = params;
  return fetch(file, {
    method: id ? PATCH : POST,
    body: formData,
  });
}

export function fetchForReassignMultipleTasks(params: MultipleReassignTaskParam): Promise<Response> {
  return fetch(bulkReassignTask, {
    method: PATCH,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
}
