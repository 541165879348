import React from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, useMediaQuery, createMuiTheme, DialogContentText, Grid } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { black, cancelColor, cobalt, ERROR_MESSAGE_TEXT, white } from "../theme";

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  content: string;
  alignActionsOnRightSide?: boolean;
  primaryLabel: string;
  secondaryLabel: string;
  secondaryHandler?: () => void;
  primaryHandler?: () => void;
  warningContent?: string;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps): JSX.Element {
  const { primaryHandler, secondaryHandler, open, primaryLabel, secondaryLabel, title, content, warningContent } = props;
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDesktop = useMediaQuery("(min-width:600px)");

  const start = content.indexOf("<b>");
  const end = content.indexOf("</b>");

  const myTheme = createMuiTheme({
    overrides: {
      MuiDialog: {
        paperWidthXs: {
          maxWidth: isMobile ? "280px" : "560px",
          width: isMobile ? "280px" : "560px",
          position: "relative",
          right: isDesktop ? "70px" : "0px",
        },
      },
      MuiTypography: {
        h6: { fontSize: "1.25rem" },
      },
    },
  });

  const commonButtonStyle = {
    height: "36px",
    padding: "8px 16px",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0.32px",
    textTransform: "uppercase",
  };
  const styles: any = {
    cancelBtn: {
      ...commonButtonStyle,
      color: cancelColor,
      marginTop: isMobile ? "12px" : "auto",
      marginRight: "auto",
      marginLeft: "inherit",
      backgroundColor: white,
      top: "32%",
      right: "20px",
      border: `2px solid ${cancelColor}`,
      display: secondaryLabel ? "flex" : "none"
    },
    confirmBtn: {
      ...commonButtonStyle,
      color: white,
      background: cobalt,
      letterSpacing: "0.32px",
      marginTop: isMobile ? "12px" : "auto",
      marginRight: "auto",
      marginLeft: "inherit",
    },
    dialogContent: { color: black, fontSize: "0.9375em" },
    dialogWarningContent: {
      color: ERROR_MESSAGE_TEXT,
      fontSize: "0.9375em",
      lineHeight: "22.4px",
      letterSpacing: "0.32px"
    },
    dialogActions: {
      marginRight: "15px",
      marginBottom: "15px",
      paddingLeft: "0.820em",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: "flex-end",
      position: "relative",
      width: isMobile ? "fit-content" : "auto",
    },
    butonsContainer: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    dialogContainer: {
      position: "absolute",
      left: "10%",
      top: isMobile ? "20%" : "10%",
      height: "600px",
    },
    dialogTitle: {
      paddingBottom: "5px",
      fontSize: "20px",
    },
  };

  return (
    <div>
      <MuiThemeProvider theme={myTheme}>
        <Dialog open={open} onClose={() => {}} maxWidth="xs" style={styles.dialogContainer}>
          <DialogTitle style={styles.dialogTitle}>
            <b>{title}</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={styles.dialogContent}>
              {start != -1 && end != -1 ? ( // if we need to show a bold text on dialog
                <p>
                  {content.substring(0, start)}
                  <b data-testid="highlightedText">{content.substring(start + 3, end)}</b> {content.substring(end + 4)}{" "}
                </p>
              ) : (
                content
              )}
            </DialogContentText>
            {warningContent && <DialogContentText style={styles.dialogWarningContent}>{warningContent}</DialogContentText>}
          </DialogContent>
          <DialogActions style={styles.dialogActions}>
            <Grid style={styles.butonsContainer}>
              <Button onClick={primaryHandler} style={styles.confirmBtn} variant="text" data-testid="confirmButton">
                {primaryLabel}
              </Button>
              <Button onClick={secondaryHandler} style={styles.cancelBtn} variant="text" data-testid="cancelButton">
                {secondaryLabel}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}
