import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { reportStyles } from "./reportStyles";
import Spinner from "../common/Spinner";
import ReportsBloc, { defaultReportsState, ReportsState, updateReportsState } from "../../rxjs/reportsBloc";
import { useBloc } from "../../rxjs/blocBuilder";
import { getIAPEmail } from "../../services/GcpIAMService";

interface ObjectAnalysisReportProps {
  externalAnalysisUrl: string;
}

function ObjectAnalysisReport({ externalAnalysisUrl }: ObjectAnalysisReportProps): JSX.Element {
  const reportsBloc = ReportsBloc.getInstance();
  const reportState = useBloc<ReportsState>(defaultReportsState, reportsBloc.getSubject());
  const { isLoading, isSignedIn } = reportState.data || defaultReportsState;
  const { t } = useTranslation();
  const {
    reportContainer,
    reportPaperStyle,
    iframeStyle,
    signInPageStyle,
    signInHeading,
    signInInfo,
    signInButton,
    signInContentStyle,
  } = reportStyles();

  useEffect(() => {
    (async () => {
      updateReportsState({
        isLoading: true,
      });
      const signedEmail = await getIAPEmail();
      if (signedEmail) {
        updateReportsState({
          isLoading: true,
          isSignedIn: true,
          googleAccount: signedEmail,
        });
      } else {
        updateReportsState({
          isLoading: false,
          isSignedIn: false,
        });
      }
    })();

    const handleAuthComplete = async (event: MessageEvent) => {
      if (event.origin === window.location.origin && event.data.action === "oauth_complete") {
        const signedEmail = await getIAPEmail();
        if (signedEmail) {
          updateReportsState({
            isLoading: true,
            isSignedIn: true,
            googleAccount: signedEmail,
          });
        } else {
          updateReportsState({
            isLoading: false,
            isSignedIn: false,
          });
        }
      }
    };

    window.addEventListener("message", handleAuthComplete);

    return () => {
      window.removeEventListener("message", handleAuthComplete);
    };
  }, []);

  const handleSignIn = () => {
    const clientId = (window as any).REACT_APP_IAP_CLIENT_ID;
    const redirectUri = `${window.location.origin}/oauth/callback`;
    const oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${encodeURIComponent("openid email")}&prompt=select_account`;
    let left = (screen.width - 500) / 2;
    let top = (screen.height - 600) / 4;

    let newPopup = open(oauthUrl, "Sign in", `resizable=yes, height=600, width=500,left=${left},top=${top}`) as Window;
    newPopup.focus();
  };

  return (
    <div style={reportContainer}>
      <Paper style={reportPaperStyle} className="reportPaper">
        {isSignedIn ? (
          <iframe
            id="report-frame"
            src={externalAnalysisUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            onLoad={() => {
              updateReportsState({
                isLoading: false,
              });
            }}
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            style={iframeStyle}
          />
        ) : (
          <Grid container direction="column" alignItems="center" justify="center" style={signInPageStyle}>
            <Grid item>
              <Typography style={signInHeading}>{t("reports.signInHeading")}</Typography>
            </Grid>
            <Grid item style={signInContentStyle}>
              <Typography variant="body1" style={signInInfo}>
                {t("reports.signInContent")}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" style={signInButton} onClick={handleSignIn}>
                {t("reports.signInText")}
              </Button>
            </Grid>
          </Grid>
        )}
        <Spinner open={isLoading} isFormLoad />
      </Paper>
    </div>
  );
}

export default ObjectAnalysisReport;
