import React, { useEffect, useRef, useState } from "react";
import List, { ListItem, ListItemText } from "@material/react-list";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { StateType } from "../../redux/types";
import { getObjectDefinitionState } from "../../datastore/objectDefinitionSelector";
import { ObjectDefinitionState, ObjectDefinition } from "../../datastore/dataStoreTypes";
import { objectDefinitionReducer } from "../../datastore/objectDefinitionReducer";
import { amethystPurple, coralOrange, goldenrodYellow, turquoise } from "../theme";
import { circleIcon } from "./objectDataStyles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps<{}> {
  onDrawerClose: any;
  handleNavLinkClick: (event: React.MouseEvent<HTMLAnchorElement>, key?: string, version?: number) => void;
}

// The objects in redux-state that are used by the current component
interface StateProps {
  objectDefinitionState: ObjectDefinitionState;
}

// Redux actions that the component uses
interface DispatchProps {
  getRemoteObjectDefinitions: () => void;
}

const ObjectDefinitionNavList = React.memo(
  (props: OwnProps & StateProps & DispatchProps): JSX.Element => {
    const { getRemoteObjectDefinitions, objectDefinitionState, onDrawerClose, handleNavLinkClick } = props;
    const [loaded, setLoaded] = useState(false);

    var objectDefinitions: ObjectDefinition[] = [];
    if (objectDefinitionState.remoteLoadingStatus === "fullyLoaded") {
      objectDefinitions = objectDefinitionState.items
        ? Object.keys(objectDefinitionState.items).map((id: string) => {
            return objectDefinitionState.items[id];
          })
        : [];
    }

    useEffect(() => {
      if (!loaded && objectDefinitionState.remoteLoadingStatus !== "fullyLoaded") {
        getRemoteObjectDefinitions();
        setLoaded(true);
      }
    }, [loaded, objectDefinitionState.remoteLoadingStatus, getRemoteObjectDefinitions]);

    let colorIndex = 0;
    const CircleIcon = () => {
      const colors = [turquoise, amethystPurple, goldenrodYellow, coralOrange];
      const currentColor = colors[colorIndex];
      colorIndex = (colorIndex + 1) % colors.length;

      const style = {
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: currentColor,
      };

      return <div style={style}></div>;
    };

    return (
      <React.Fragment>
        <List singleSelection={true} onClick={onDrawerClose}>
          {objectDefinitions
            ? objectDefinitions.map((x: ObjectDefinition) => {
                return (
                  <NavLink key={x.key} exact to={`/objectdata/${x.key}/${x.version}`} onClick={e => handleNavLinkClick(e, x.key, x.version)}>
                    <ListItem className="pointer">
                      <div style={circleIcon}>
                        <CircleIcon />
                      </div>
                      <ListItemText primaryText={x.label} />
                    </ListItem>
                  </NavLink>
                );
              })
            : null}
        </List>
      </React.Fragment>
    );
  }
);

function mapStateToProps(state: StateType): StateProps {
  const objectDefinitionState = getObjectDefinitionState(state);
  return {
    objectDefinitionState,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  const actions = {
    getRemoteObjectDefinitions: () => dispatch(objectDefinitionReducer.getEntities()),
  };
  return actions;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ObjectDefinitionNavList));
