import { DynamicJson } from "../datastore/dataStoreTypes";
import { Alert } from "../redux/reducers/alertReducer";
import { AppDefinition, CreateProcessInstance, DownloadUserRequest, JuakaliUser, Paging, ReassignParams } from "../redux/types";
import { PagedResult, PagedApiResult } from "./commonTypes";

export enum TaskAssignment {
  CANDIDATE = "CANDIDATE",
  INVOLVED = "INVOLVED",
  TEAM = "TEAM",
}

export enum TaskStatus {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export enum TaskState {
  OPEN = "OPEN",
}

export enum SortType {
  DUE_DATE_ASCENDING = "DUE_DATE_ASCENDING",
  DUE_DATE_DESCENDING = "DUE_DATE_DESCENDING",
  TASK_NAME_ASCENDING = "TASK_NAME_ASCENDING",
  TASK_NAME_DESCENDING = "TASK_NAME_DESCENDING",
  ASSIGNEE_NAME_ASCENDING = "ASSIGNEE_ASCENDING",
  ASSIGNEE_NAME_DESCENDING = "ASSIGNEE_DESCENDING",
  PROCESS_NAME_ASCENDING = "PROCESS_NAME_ASCENDING",
  PROCESS_NAME_DESCENDING = "PROCESS_NAME_DESCENDING",
  CREATED_ON_ASCENDING = "CREATE_TIME_ASCENDING",
  CREATED_ON_DESCENDING = "CREATE_TIME_DESCENDING",
}

export enum QuickFilter {
  ALL = "ALL",
  NEW = "NEW",
  TODAY = "TODAY",
  WEEK = "WEEK",
  OVERDUE = "OVERDUE",
}

export interface QueryParams {
  page: number;
  size: number;
  filterParams?: TaskQueryFilterParams;
  sortType: SortType;
  quickFilterType?: QuickFilter;
}

export interface TaskQueryParams extends QueryParams {
  countAvailability?: boolean;
  assignment: TaskAssignment;
  state: TaskState;
  filterNew?: boolean;
  sortParams?: [{ param: string; order: string }];
  userId?: string;
}

export interface TaskQueryFilterParams {
  dueDate?: string | null;
  dateType?: "TODAY" | "WEEK" | "OVERDUE";
  taskName?: string;
  assignee?: string;
  status?: string | null;
}

export interface KeyValuePair {
  [key: string]: string | null;
}

export interface TaskViewRepresentation {
  processInstanceId: string;
  processDefinitionId: string;
  category: string;
  dueDate: Date;
  assignee: string;
  id: string;
  name: string;
  description: string;
  priority: number;
  owner: string;
  executionId: string;
  taskDefinitionId: string;
  createTime: string;
  taskDefinitionKey: string;
  tenantId: string;
  //identity linkedModels
  candidates: string;
  processDefinitionKey: string;
  processDefinitionName: string;
  assigneeName: string;
  formKey?: string;
  taskStatus: string;
}

export enum TaskTableColumn {
  PROCESS_INSTANCE_ID = "processInstanceId",
  TASK_ID = "taskId",
  PROCESS_NAME = "processName",
  DUE_DATE = "dueDate",
  TASK_NAME = "taskName",
  ASSIGNEE = "assignee",
  CANDIDATES = "candidates",
  ASSIGNEE_NAME = "assigneeName",
  TASK_DETAILS = "taskDetails",
  CREATED_ON = "createTime",
  SELECT_ROW = "selectRow",
}

export enum SortDirection {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export enum TaskTableType {
  INVOLVED = "involved",
  CANDIDATE = "candidate",
  TEAM = "team",
}

export const sortTypeAndColumnMapper: DynamicJson = {
  [SortType.PROCESS_NAME_ASCENDING]: { columnName: TaskTableColumn.PROCESS_NAME, sortDirection: SortDirection.ASCENDING },
  [SortType.PROCESS_NAME_DESCENDING]: { columnName: TaskTableColumn.PROCESS_NAME, sortDirection: SortDirection.DESCENDING },
  [SortType.DUE_DATE_ASCENDING]: { columnName: TaskTableColumn.DUE_DATE, sortDirection: SortDirection.ASCENDING },
  [SortType.DUE_DATE_DESCENDING]: { columnName: TaskTableColumn.DUE_DATE, sortDirection: SortDirection.DESCENDING },
  [SortType.TASK_NAME_ASCENDING]: { columnName: TaskTableColumn.TASK_NAME, sortDirection: SortDirection.ASCENDING },
  [SortType.TASK_NAME_DESCENDING]: { columnName: TaskTableColumn.TASK_NAME, sortDirection: SortDirection.DESCENDING },
  [SortType.ASSIGNEE_NAME_ASCENDING]: { columnName: TaskTableColumn.ASSIGNEE_NAME, sortDirection: SortDirection.ASCENDING },
  [SortType.ASSIGNEE_NAME_DESCENDING]: { columnName: TaskTableColumn.ASSIGNEE_NAME, sortDirection: SortDirection.DESCENDING },
  [SortType.CREATED_ON_ASCENDING]: { columnName: TaskTableColumn.CREATED_ON, sortDirection: SortDirection.ASCENDING },
  [SortType.CREATED_ON_DESCENDING]: { columnName: TaskTableColumn.CREATED_ON, sortDirection: SortDirection.DESCENDING },
};

export const columnAndSortTypeMapper: DynamicJson = {
  [TaskTableColumn.DUE_DATE + SortDirection.ASCENDING]: SortType.DUE_DATE_ASCENDING,
  [TaskTableColumn.CREATED_ON + SortDirection.ASCENDING]: SortType.CREATED_ON_ASCENDING,
  [TaskTableColumn.TASK_NAME + SortDirection.ASCENDING]: SortType.TASK_NAME_ASCENDING,
  [TaskTableColumn.PROCESS_NAME + SortDirection.ASCENDING]: SortType.PROCESS_NAME_ASCENDING,
  [TaskTableColumn.ASSIGNEE_NAME + SortDirection.ASCENDING]: SortType.ASSIGNEE_NAME_ASCENDING,
  [TaskTableColumn.DUE_DATE + SortDirection.DESCENDING]: SortType.DUE_DATE_DESCENDING,
  [TaskTableColumn.CREATED_ON + SortDirection.DESCENDING]: SortType.CREATED_ON_DESCENDING,
  [TaskTableColumn.TASK_NAME + SortDirection.DESCENDING]: SortType.TASK_NAME_DESCENDING,
  [TaskTableColumn.PROCESS_NAME + SortDirection.DESCENDING]: SortType.PROCESS_NAME_DESCENDING,
  [TaskTableColumn.ASSIGNEE_NAME + SortDirection.DESCENDING]: SortType.ASSIGNEE_NAME_DESCENDING,
};

export const defaultTasksPagedResult: PagedResult<TaskViewRepresentation> = {
  content: [],
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalHeadCount: 0,
  totalElements: 0,
  totalAll: 0,
  totalNew: 0,
  totalToday: 0,
  totalThisWeek: 0,
  totalOverdue: 0,
  elementsInPage: 0,
  lastPage: false,
  firstPage: false,
};

export const defaultTasksPagedApiResult: PagedApiResult<TaskViewRepresentation> = {
  error: null,
  result: defaultTasksPagedResult,
};

export type ApiResultType = "involvedTasksApiResult" | "teamTasksApiResult" | "candidateTasksApiResult";

export type TasksLoadingType = "involvedTasksLoading" | "candidateTasksLoading" | "teamTasksLoading";

export type TaskQueryParamsType = "assignedTaskQueryParams" | "candidateTaskQueryParams" | "teamTaskQueryParams";

export type TasksStatusType = "involvedTasksStatus" | "candidateTasksStatus" | "teamTasksStatus";

export interface NotificationData {
  id: string;
  groupName?: string;
  taskId: string;
  taskName?: string;
}

interface NotificationTask {
  count: number;
  notificationData: NotificationData[];
}

export interface NotificationParam {
  userId: string;
}

export interface TaskNotification {
  assignedTasks: NotificationTask;
  candidateTasks: NotificationTask;
}

export const defaultNotificationTask: NotificationTask = {
  count: 0,
  notificationData: [],
};

export interface DashboardStateProps {
  pendingTasks: TaskViewRepresentation[];
  candidateTasks: TaskViewRepresentation[];
  teamTasks: TaskViewRepresentation[];
  involvedTasksLoading: boolean;
  candidateTasksLoading: boolean;
  teamTasksLoading: boolean;
  involvedTasksPaging: Paging;
  candidateTasksPaging: Paging;
  teamTasksPaging: Paging;
  assignedTaskQueryParams: TaskQueryParams;
  candidateTaskQueryParams: TaskQueryParams;
  teamTaskQueryParams: QueryParams;
  involvedTasksStatus: string;
  candidateTasksStatus: string;
  teamTasksStatus: string;
  usersList: JuakaliUser[];
  drawerStatus?: boolean;
}

export interface DashboardDispatchProps {
  fetchTasks: (params: Partial<QueryParams & TaskQueryParams>) => void;
  resetTasks: () => void;
  fetchUsers: (params: DownloadUserRequest) => void;
  setStatus: (assignment: TaskAssignment, status?: string) => void;
  addAlert: (alert: Alert) => void;
  reassignTask: (params: ReassignParams) => void;
}

export interface MultipleReassignTaskParam {
  taskDetails: { fromUserId: string; taskId: string }[];
  toUserId: string;
}

export interface MultipleReassignTaskResponse {
  failedTaskIds: string[];
  noOfTasksReassigned: number;
}

export interface ProcessStarterStateProps {
  appDefinitions: AppDefinition[];
  createProcessInstance: CreateProcessInstance;
  isLoading: boolean;
}

export interface ProcessStarterDispatchProps {
  createProcessInstanceOnline: (instance: CreateProcessInstance) => Promise<DynamicJson>;
  fetchAppDefinitions: () => void;
  setCreateProcessInstance: (instance: CreateProcessInstance) => void;
}
