import { createMuiTheme, makeStyles } from "@material-ui/core";
import { cobalt, sliverGray, solitude, white } from "../theme";

export const toggleListStyles = makeStyles({
  container: {
    backgroundColor: `${cobalt} !important`,
    borderRadius: "4px 4px 0px 0px",
  },
  expandIcon: {
    alignItems: "center",
    backgroundColor: solitude,
    borderRadius: 5,
    display: "flex",
    height: 30,
    justifyContent: "center",
    width: 30,
  },
  headerText: {
    color: white,
  },
  itemText: {
    color: cobalt,
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "26px",
    letter: "1%",
  },
  list: { border: `1px solid ${sliverGray}`, padding: "8px 0px", borderRadius: "0px 0px 10px 10px" },
  listButton: {
    backgroundColor: `${white} !important`,
    padding: "8px 16px",
  },
});

export const toggleListTheme = createMuiTheme({
  overrides: {
    MuiListItemIcon: {
      root: {
        alignItems: "center",
        minWidth: 30,
        minHeight: 30,
      },
    },
    MuiListItemText: {
      primary: {
        wordBreak: "break-word",
      },
      root: {
        margin: "0px !important",
      },
    },
    MuiSvgIcon: {
      root: {
        color: cobalt,
        height: 24,
        width: 24,
      },
    },
    MuiList: {
      padding: {
        alignItems: "center",
      },
    },
  },
});
