import { createMuiTheme, makeStyles } from "@material-ui/core";
import theme, { snowWhite, cancelColor, lightPink, midNightBlue, cobalt, white, black, transparentBlack1, transparentBlack2 } from "../theme";

export const reportStylesheet = {
  spacing: { marginTop: "0em", marginBottom: "0.5em", marginLeft: "1em", paddingTop: "1em" },
  marginLeft: { marginLeft: "1em" },
  positioning: { zIndex: 2, position: "absolute" },
  background: { backgroundColor: "white" },
};

export const reportStyles = () => ({
  tableTitleStyle: {
    order: 2,
    color: midNightBlue,
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0.2px",
    wordBreak: "break-all",
    textAlign: "left" as "left",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: "3" as React.CSSProperties["WebkitLineClamp"],
    WebkitBoxOrient: "vertical",
    paddingTop: "8px",
    paddingBottom: "8px",
  } as React.CSSProperties,
  reportContainer: {
    paddingTop: "16px",
  },
  reportPaperStyle: {
    boxShadow: `0px 2px 4px -1px ${black},0px 4px 5px 0px ${transparentBlack1},0px 1px 10px 0px ${transparentBlack2}`,
    overflow: "auto",
    position: "relative" as any,
    paddingTop: "16px",
    height: "60vh",
  },
  iframeStyle: {
    position: "absolute" as any,
    top: 0,
    left: 0,
    border: "none",
    borderRadius: "4px",
  },
  signInPageStyle: {
    textAlign: "center" as any,
    height: "100%",
  },
  signInHeading: {
    color: black,
    textAlign: "center" as any,
    fontFamily: "Inter",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.64px",
  },
  signInInfo: {
    color: black,
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: "0.36px",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  signInButton: {
    height: "36px",
    padding: "8px 16px",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.32px",
    textTransform: "uppercase" as any,
    color: white,
    background: cobalt,
    marginTop: "auto",
    marginRight: "auto",
    marginLeft: "inherit",
  },
  iconStyle: { display: "flex", justifyContent: "center", paddingRight: 10 },
  activeStyle: {
    backgroundColor: snowWhite,
    color: cancelColor,
    border: `2px solid ${cancelColor}`,
  },
  inactiveStyle: {
    backgroundColor: lightPink,
    color: midNightBlue,
    border: "none",
  },
  signInContentStyle: {
    width: "60%",
  },
});

export const activeTabButtonStyle = makeStyles({
  commonStyle: {
    maxHeight: 52,
    height: 52,
    padding: 16,
    borderRadius: 4,
    border: "none",
    backgroundColor: lightPink,
    textTransform: "none",
    fontFamily: "inter",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "28px",
  },
  disabledButtonStyle: {
    maxHeight: 52,
    height: 52,
    padding: 16,
    borderRadius: 4,
    border: "none",
    backgroundColor: lightPink,
    textTransform: "none",
    fontFamily: "inter",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "28px",
    opacity: 0.6,
    cursor: "not-allowed",
  },
});

export const exportIconTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: "8px !important",
      },
    },
  },
});
