import { Tooltip, withStyles } from "@material-ui/core";
import {
  aviaryBlue,
  black,
  cobalt,
  grey,
  inactiveColor,
  lavenderBlue,
  solitude,
  strongRed,
  TOOLTIP_BACKGROUND_COLOR,
  TRANSPARENT_BUTTON_BACKGROUND,
  white,
} from "../../theme";
import { EMPTY } from "../../../constants";

export const textFieldColorTheme = {
  MuiFormLabel: {
    root: {
      "&$focused": {
        color: "#134A9F",
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      "&$focused $notchedOutline": {
        borderColor: "#134A9F",
      },
    },
  },
};

export const BootstrapTooltip = withStyles(theme => ({
  arrow: {
    color: `${strongRed} !important`,
  },
  tooltip: {
    backgroundColor: `${strongRed} !important`,
    color: white,
    fontSize: 12,
    borderRadius: 4,
    fontWeight: 500,
    lineHeight: "14.06px",
    padding: "10px 12px",
  },
}))(Tooltip);

export const TablePhoneTooltip = withStyles(theme => ({
  arrow: {
    color: `${solitude} !important`,
  },
  tooltip: {
    backgroundColor: `${solitude} !important`,
    color: grey,
    fontSize: 14,
    borderRadius: 4,
    fontWeight: 500,
    fontStyle: "normal",
    padding: 8,
    width: 200,
  },
}))(Tooltip);

export const popperPropsToolTip = {
  modifiers: {
    offset: {
      enabled: true,
      offset: "0, 10",
    },
  },
};

export const tableStyles = (props: any) => {
  return {
    tableCell: {
      width: props.width,
    },
    tableCellRequired: {
      color: strongRed,
      marginLeft: "4px",
    },
    tableRowCell: {
      verticalAlign: "middle",
    },
    columnName: {
      fontWeight: 600,
    },
    errorMessage: {
      color: strongRed,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "22.4px",
    },
    tableTitle: {
      color: black,
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "23.4px",
    },
    boxStyle: {
      overflowX: "auto",
      overflowY: "hidden",
      width: "100%",
      scrollbarWidth: "thin",
    },
  };
};
export const commonToolBarStyles = (
  isMobileView?: boolean,
  isPopoverOpened?: boolean,
  isTabletView?: boolean,
  drawerStatus?: boolean,
  drawerCloseMediaQuery?: boolean
) => ({
  quickFilters: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    marginBottom: isMobileView || (isTabletView && drawerStatus) ? "20px" : !drawerStatus && drawerCloseMediaQuery ? "20px" : "0px",
    whiteSpace: "nowrap",
  },
  filters: {
    paddingLeft: 0,
    display: "flex",
    flexDirection: "row",
    alignSelf: isMobileView ? "end" : EMPTY,
    marginLeft: "auto",
    justifyContent: "flex-end",
    width: isMobileView && "100%",
  },
  menuIcon: {
    borderColor: isPopoverOpened ? cobalt : aviaryBlue,
    borderStyle: "solid",
    borderWidth: isPopoverOpened ? "1.4px" : "1px",
    borderRadius: 4,
    backgroundColor: isPopoverOpened ? lavenderBlue : TRANSPARENT_BUTTON_BACKGROUND,
  },
  helperText: {
    flexShrink: 0,
    color: inactiveColor,
    fontFamily: "inter",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "1%",
  },
});

export const CommonToolTip = withStyles(theme => ({
  tooltip: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
    backgroundColor: TOOLTIP_BACKGROUND_COLOR,
    marginTop: "6px !important",
  },
}))(Tooltip);

export const stackIndex = { zIndex: 1200 };
