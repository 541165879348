import { makeStyles } from "@material-ui/styles";
import { aviaryBlue, ghostWhite, primaryMobile, solitude } from "../theme";

export const useStyles = makeStyles(x => ({
  progress: {
    marginLeft: "40%",
    marginRight: "40%",
    marginTop: "20%",
    position: "absolute",
    zIndex: 110,
  },
  button: {
    marginLeft: "50px",
    marginTop: "20px",
    marginBottom: "40px",
    marginRight: "100px",
  },
  grid: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "wrap",
  },
  displayFlex: {
    display: "flex",
    marginRight: "65px",
    position: "absolute",
    right: 0,
    top: "6em",
  },
  messageContainer: {
    padding: "0px 24px",
  },
  warningMessage: {
    color: "#FF9800",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.02em",
    textAlign: "right",
    marginTop: "-13px",
    paddingBottom: "16px",
  },
  errorMessage: {
    paddingBottom: "16px",
    color: "#D00B0B",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.02em",
  },
  readOnlySection: {
    padding: "2em",
  },
  versionContainer: { marginTop: "1.5em" },
  versionBox: { border: `1px solid ${solitude}`, backgroundColor: solitude, borderRadius: "5px", padding: "3px 9px" },
  versionText: { color: primaryMobile, fontWeight: 500, fontSize: "14px", lineHeight: "140%", letterSpacing: ".02em" },
}));

export const stylesForTableOptions = makeStyles({
  justifyTableHead: {
    "& > span": {
      justifyContent: "flex-end",
    },
  },
  disableSort: {
    whiteSpace: "nowrap",
    pointerEvents: "none",
  },
});

export const customToolBarStyle = {
  button: { order: 1 },
  iconButton: {
    borderColor: aviaryBlue,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: 4,
    padding: "0px !important",
    width: 40,
    height: 40,
  },
  headerDisplayStyle: { display: "flex" },
  headerTextStyle: { marginRight: "auto" },
  exportIconStyle: { display: "flex" },
  reportTabStyle: { paddingTop: "16px" },
  buttonContainer: { marginRight: "16px" },
  toolBarContainer: { padding: "24px" },
  containerStyle: { paddingLeft: "0px", paddingRight: "0px", alignItems: "flex-start", display: "flex" },
};

export const objectDataTableBackground = { backgroundColor: ghostWhite };
export const circleIcon = {
  padding: 12,
};
