import React, { useEffect } from "react";
import { showNotification } from "../../util/notification";
import i18n from "../../i18n";
import Spinner from "./Spinner";

export const OAuthCallBackComponent = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get("code");
        if (window.opener && authCode) {
          window.opener.postMessage(
            { action: "oauth_complete", code: authCode },
            window.opener.location.origin
          );
          window.close();
        } else {
          showNotification("error", i18n.t("common.serverErrorTryAgain"));
        }
      }, []);
    return <Spinner open/>
}