import React from "react";

const EmptyNotificationIcon = () => {
  return (
    <div>
      <svg width="207" height="185" viewBox="0 0 207 185" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_752_55093)">
          <path
            d="M115.541 40.4812H66.5C65.3825 40.4825 64.3111 40.933 63.5208 41.7336C62.7306 42.5343 62.286 43.6198 62.2847 44.7521V155.185L61.7226 155.359L49.6921 159.092C49.1219 159.268 48.5061 159.208 47.9797 158.924C47.4534 158.641 47.0596 158.157 46.8847 157.58L11.0993 39.1458C10.9252 38.5681 10.9845 37.944 11.2643 37.4106C11.5442 36.8772 12.0215 36.4782 12.5916 36.3013L31.1306 30.5497L84.8761 13.8815L103.415 8.12994C103.697 8.04196 103.994 8.01122 104.288 8.03947C104.581 8.06772 104.867 8.15441 105.128 8.29458C105.388 8.43475 105.619 8.62564 105.807 8.85633C105.995 9.08703 106.136 9.353 106.223 9.63902L115.37 39.9117L115.541 40.4812Z"
            fill="#F2F2F2"
          />
          <path
            d="M126.243 39.9126L115.218 3.42703C115.035 2.81911 114.735 2.25377 114.336 1.76331C113.937 1.27285 113.446 0.866893 112.892 0.568625C112.338 0.270356 111.731 0.0856235 111.107 0.0249902C110.482 -0.0356431 109.852 0.0290111 109.252 0.215252L83.1872 8.29879L29.4447 24.9698L3.37989 33.0562C2.16906 33.4329 1.15521 34.281 0.560892 35.4143C-0.0334306 36.5476 -0.159673 37.8736 0.20988 39.1011L37.8893 163.794C38.1895 164.785 38.7953 165.652 39.6177 166.268C40.4401 166.885 41.4358 167.218 42.4588 167.219C42.9323 167.219 43.4031 167.147 43.8554 167.005L61.7228 161.465L62.2849 161.288V160.693L61.7228 160.867L43.6897 166.462C42.6209 166.792 41.4665 166.679 40.4798 166.148C39.493 165.617 38.7545 164.711 38.4261 163.629L0.749557 38.9331C0.586871 38.3969 0.530171 37.8335 0.582701 37.2751C0.635231 36.7167 0.795956 36.1743 1.05568 35.6789C1.31541 35.1835 1.66903 34.7449 2.0963 34.3882C2.52356 34.0315 3.01608 33.7636 3.54564 33.6001L29.6104 25.5137L83.3531 8.84548L109.418 0.759095C109.82 0.634895 110.237 0.571567 110.657 0.571171C111.558 0.573221 112.435 0.867621 113.16 1.41125C113.884 1.95488 114.417 2.71917 114.681 3.59218L125.655 39.9126L125.83 40.482H126.414L126.243 39.9126Z"
            fill="#BFC3CE"
          />
          <path
            d="M53.4375 12.8004C56.5415 12.8004 59.0579 10.2508 59.0579 7.10577C59.0579 3.96071 56.5415 1.41113 53.4375 1.41113C50.3334 1.41113 47.817 3.96071 47.817 7.10577C47.817 10.2508 50.3334 12.8004 53.4375 12.8004Z"
            fill="#134A9F"
          />
          <path
            d="M53.4374 10.7111C55.403 10.7111 56.9965 9.09659 56.9965 7.10504C56.9965 5.11349 55.403 3.49902 53.4374 3.49902C51.4718 3.49902 49.8784 5.11349 49.8784 7.10504C49.8784 9.09659 51.4718 10.7111 53.4374 10.7111Z"
            fill="white"
            fill-opacity="0.7"
          />
          <path
            d="M169.354 165.763H74.3685C73.7352 165.763 73.1281 165.507 72.6803 165.054C72.2325 164.6 71.9806 163.985 71.9799 163.343V48.0267C71.9806 47.385 72.2325 46.7698 72.6803 46.3161C73.1281 45.8624 73.7352 45.6072 74.3685 45.6064H169.354C169.987 45.6072 170.594 45.8624 171.042 46.3161C171.49 46.7698 171.742 47.385 171.743 48.0267V163.343C171.742 163.985 171.49 164.6 171.042 165.054C170.594 165.507 169.987 165.763 169.354 165.763Z"
            fill="#E6E6E6"
          />
          <path
            d="M125.655 39.9119H66.5C65.2335 39.9137 64.0194 40.4242 63.1239 41.3316C62.2284 42.239 61.7244 43.4691 61.7227 44.7523V160.866L62.2847 160.692V44.7523C62.2861 43.62 62.7306 42.5345 63.5208 41.7338C64.3111 40.9331 65.3825 40.4827 66.5 40.4813H125.829L125.655 39.9119ZM177.223 39.9119H66.5C65.2335 39.9137 64.0194 40.4242 63.1239 41.3316C62.2284 42.239 61.7244 43.4691 61.7227 44.7523V175.159C61.7244 176.443 62.2284 177.673 63.1239 178.58C64.0194 179.488 65.2335 179.998 66.5 180H177.223C178.489 179.998 179.703 179.488 180.599 178.58C181.494 177.673 181.998 176.443 182 175.159V44.7523C181.998 43.4691 181.494 42.239 180.599 41.3316C179.703 40.4242 178.489 39.9137 177.223 39.9119ZM181.438 175.159C181.437 176.292 180.992 177.377 180.202 178.178C179.412 178.979 178.34 179.429 177.223 179.43H66.5C65.3825 179.429 64.3111 178.979 63.5208 178.178C62.7306 177.377 62.2861 176.292 62.2847 175.159V44.7523C62.2861 43.62 62.7306 42.5345 63.5208 41.7338C64.3111 40.9331 65.3825 40.4827 66.5 40.4813H177.223C178.34 40.4827 179.412 40.9331 180.202 41.7338C180.992 42.5345 181.437 43.62 181.438 44.7523V175.159Z"
            fill="#BFC3CE"
          />
          <path
            d="M147.715 52.4405H96.0072C95.3367 52.4397 94.6938 52.1695 94.2196 51.6891C93.7455 51.2087 93.4788 50.5573 93.478 49.8779V37.3497C93.4788 36.6703 93.7455 36.0189 94.2197 35.5385C94.6938 35.0581 95.3367 34.7879 96.0072 34.7871H147.715C148.386 34.7879 149.029 35.0581 149.503 35.5385C149.977 36.0189 150.244 36.6703 150.244 37.3497V49.8779C150.244 50.5573 149.977 51.2087 149.503 51.6891C149.029 52.1695 148.386 52.4397 147.715 52.4405Z"
            fill="#134A9F"
          />
          <path
            d="M121.861 35.6412C124.965 35.6412 127.482 33.0917 127.482 29.9466C127.482 26.8015 124.965 24.252 121.861 24.252C118.757 24.252 116.241 26.8015 116.241 29.9466C116.241 33.0917 118.757 35.6412 121.861 35.6412Z"
            fill="#134A9F"
          />
          <path
            d="M121.861 33.4147C123.752 33.4147 125.285 31.8617 125.285 29.9461C125.285 28.0305 123.752 26.4775 121.861 26.4775C119.971 26.4775 118.438 28.0305 118.438 29.9461C118.438 31.8617 119.971 33.4147 121.861 33.4147Z"
            fill="white"
            fill-opacity="0.7"
          />
          <path
            d="M34.4759 36.3949C33.9343 36.3946 33.407 36.2183 32.9715 35.8921C32.536 35.5658 32.2151 35.1067 32.056 34.5821L28.4363 22.603C28.339 22.2812 28.3053 21.9431 28.337 21.6082C28.3687 21.2732 28.4652 20.9478 28.621 20.6506C28.7768 20.3534 28.9889 20.0902 29.2452 19.876C29.5014 19.6619 29.7967 19.501 30.1143 19.4025L79.5571 4.06579C80.1985 3.86748 80.8913 3.93512 81.4835 4.25384C82.0758 4.57257 82.519 5.11635 82.716 5.76584L86.3356 17.7452C86.5312 18.395 86.4644 19.097 86.1498 19.697C85.8353 20.297 85.2987 20.7461 84.6577 20.9457L35.2149 36.2825C34.9754 36.3569 34.7264 36.3948 34.4759 36.3949Z"
            fill="#134A9F"
          />
        </g>
        <g clip-path="url(#clip1_752_55093)">
          <path
            d="M197.947 184.766L195.005 174.264C195.005 174.264 198.241 178.092 198.241 181.233L197.66 173.97C197.66 173.97 199.319 176.62 199.153 180.644C198.988 184.668 197.947 184.766 197.947 184.766Z"
            fill="#E6E6E6"
          />
          <path
            d="M134.772 175.204L131.901 164.955C131.901 164.955 135.059 168.69 135.059 171.756L134.493 164.667C134.493 164.667 136.112 167.254 135.95 171.181C135.788 175.108 134.772 175.204 134.772 175.204Z"
            fill="#E6E6E6"
          />
          <path
            d="M179.585 179.933C179.589 186.676 168.285 184.602 154.446 184.616C140.607 184.63 129.474 186.727 129.469 179.984C129.464 173.242 140.764 171.764 154.603 171.75C168.442 171.736 179.58 173.191 179.585 179.933Z"
            fill="#E6E6E6"
          />
          <path d="M207 184.612H104V184.921H207V184.612Z" fill="#3F3D56" />
          <path d="M169.886 170.828H168.076L167.215 162.572H169.886V170.828Z" fill="#A0616A" />
          <path
            d="M166.583 170.217H170.273V172.816H164.587V172.578C164.587 171.952 164.797 171.351 165.172 170.908C165.546 170.466 166.054 170.217 166.583 170.217Z"
            fill="#2F4256"
          />
          <path d="M183.184 183.012H181.375L180.514 174.757H183.184V183.012Z" fill="#A0616A" />
          <path
            d="M179.882 182.401H183.572V185H177.886V184.763C177.886 184.136 178.096 183.536 178.47 183.093C178.845 182.65 179.352 182.401 179.882 182.401Z"
            fill="#2F4256"
          />
          <path
            d="M191.53 158.487C191.655 158.308 191.745 158.098 191.795 157.873C191.844 157.648 191.851 157.413 191.815 157.184C191.779 156.955 191.701 156.739 191.586 156.549C191.472 156.36 191.324 156.203 191.152 156.089L186.95 135.839L184.263 137.207L189.262 156.786C189.119 157.14 189.09 157.543 189.181 157.919C189.273 158.295 189.477 158.619 189.757 158.829C190.037 159.039 190.372 159.12 190.698 159.057C191.025 158.994 191.321 158.791 191.53 158.487Z"
            fill="#A0616A"
          />
          <path
            d="M188.252 117.562C188.102 117.376 187.92 117.232 187.718 117.139C187.515 117.046 187.298 117.007 187.082 117.024C186.865 117.041 186.655 117.113 186.465 117.237C186.275 117.361 186.11 117.532 185.982 117.74L179.478 118.623L180.467 121.591L186.382 120.281C186.691 120.512 187.06 120.602 187.421 120.532C187.781 120.463 188.108 120.239 188.338 119.904C188.569 119.569 188.687 119.145 188.671 118.714C188.656 118.282 188.506 117.872 188.252 117.562Z"
            fill="#A0616A"
          />
          <path
            d="M177.534 156.388L180.372 181.586L183.905 180.725C183.905 180.725 183.385 162.374 185.151 160.162C186.918 157.95 182.88 154.074 182.88 154.074L177.534 156.388Z"
            fill="#2F4256"
          />
          <path
            d="M178.708 149.446L177.404 151.76C177.404 151.76 167.365 146.825 167.365 154.69C167.365 162.556 166.79 167.894 166.582 168.263C166.375 168.632 168.986 169.589 170.233 169.343C170.233 169.343 171.771 156.662 171.667 155.924C171.667 155.924 181.515 161.636 183.385 161.145C185.255 160.653 185.697 158.913 186.009 157.929C186.321 156.946 185.879 149.138 185.879 149.138L179.645 146.888L178.708 149.446Z"
            fill="#2F4256"
          />
          <path
            d="M178.883 135.557L178.512 135.029C178.512 135.029 174.35 123.951 175.985 120.961C177.62 117.972 184.755 117.532 184.978 117.708C185.201 117.884 184.446 120.167 184.966 121.222C184.966 121.222 179.438 122.64 179.067 122.816C178.695 122.991 181.931 129.49 181.931 129.49L181.559 133.446L178.883 135.557Z"
            fill="#CCCCCC"
          />
          <path
            d="M186.167 130.105C186.167 130.105 182.303 130.633 182.154 130.633C182.005 130.633 181.931 129.49 181.931 129.49L178.512 135.029C178.512 135.029 176.828 151.099 177.274 150.835C177.719 150.571 185.944 152.086 186.019 151.382C186.093 150.679 185.944 147.162 186.167 146.986C186.39 146.81 190.032 135.908 190.032 135.908C190.032 135.908 189.661 133.622 188.397 132.831C187.134 132.039 186.167 130.105 186.167 130.105Z"
            fill="#CCCCCC"
          />
          <path
            d="M188.843 134.413L190.032 135.908C190.032 135.908 191.799 153.083 190.833 153.611C189.867 154.138 187.965 154.074 187.965 154.074L186.093 145.931L188.843 134.413Z"
            fill="#CCCCCC"
          />
          <path
            d="M182.453 129.018C184.343 129.018 185.874 127.206 185.874 124.971C185.874 122.736 184.343 120.924 182.453 120.924C180.564 120.924 179.032 122.736 179.032 124.971C179.032 127.206 180.564 129.018 182.453 129.018Z"
            fill="#A0616A"
          />
          <path
            d="M181.651 125.602C181.605 124.762 180.709 124.733 179.998 124.725C179.286 124.717 178.436 124.747 178.033 124.053C177.767 123.595 177.818 122.958 178.038 122.467C178.258 121.975 178.619 121.597 178.975 121.233C179.893 120.294 180.839 119.362 181.941 118.761C183.043 118.16 184.335 117.917 185.478 118.4C186.882 118.993 188.781 122.044 188.945 123.797C189.108 125.55 188.516 127.337 187.518 128.647C186.52 129.957 184.235 129.428 182.797 129.894C183.671 128.431 183.095 125.771 181.694 125.088L181.651 125.602Z"
            fill="#2F4256"
          />
          <path
            d="M151.161 177.95C155.697 177.95 159.374 173.6 159.374 168.235C159.374 162.869 155.697 158.52 151.161 158.52C146.625 158.52 142.949 162.869 142.949 168.235C142.949 173.6 146.625 177.95 151.161 177.95Z"
            fill="#134A9F"
          />
          <path
            d="M145.677 157.829C144.539 157.829 143.425 157.439 142.47 156.706C141.515 155.972 140.761 154.928 140.298 153.698L127.266 118.981C126.834 117.831 126.606 116.588 126.595 115.33C126.584 114.072 126.791 112.824 127.203 111.664C127.614 110.504 128.222 109.456 128.989 108.584C129.756 107.712 130.665 107.034 131.662 106.593C132.658 106.152 133.72 105.957 134.782 106.019C135.844 106.081 136.885 106.4 137.839 106.956C138.794 107.511 139.642 108.292 140.332 109.249C141.022 110.207 141.539 111.321 141.852 112.524L151.288 148.832C151.558 149.872 151.619 150.971 151.465 152.043C151.31 153.115 150.946 154.129 150.4 155.004C149.854 155.879 149.143 156.59 148.322 157.081C147.502 157.572 146.596 157.828 145.677 157.829H145.677Z"
            fill="#134A9F"
          />
        </g>
        <defs>
          <clipPath id="clip0_752_55093">
            <rect width="182" height="180" fill="white" />
          </clipPath>
          <clipPath id="clip1_752_55093">
            <rect width="103" height="79" fill="white" transform="translate(104 106)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EmptyNotificationIcon;
