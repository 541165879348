import React, { Fragment, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Logout from "./LogoutComponent";
import { logoutStyles, logoutViewStyle } from "../styles";
import { AccountContext } from "../../../App";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getColor } from "../../Dashboard/utils/dashboardHelper";
import { EMPTY } from "../../../constants";

export const LogoutView = (props: any) => {
  const { open, handleClose, handleLogout, handleClick, mobileView } = props;

  const classes: any = logoutStyles();
  const { accountIcon, expandIcon } = logoutViewStyle(mobileView);

  const accountContext = useContext(AccountContext);
  const userEmail = accountContext && accountContext.email ? accountContext.email.split("@")[0] : EMPTY;

  const renderUserInfo = () => {
    if (accountContext) {
      return (
        <div className={classes.userInfoBlock} onClick={handleClick}>
          <AccountCircleOutlinedIcon style={accountIcon} htmlColor={getColor(userEmail)} />
          {!mobileView && (
            <>
              <Typography variant="overline" className={classes.userInfoText} color="primary" display="block">
                {userEmail}
              </Typography>
              {open ? <ExpandLessIcon style={expandIcon} /> : <ExpandMoreIcon style={expandIcon} />}
            </>
          )}
        </div>
      );
    }
    return <div />;
  };

  return (
    <Fragment>
      {renderUserInfo()}
      {accountContext && <Logout open={open} handleClose={handleClose} handleLogout={handleLogout} mobileView={mobileView} />}
    </Fragment>
  );
};
