import { createMuiTheme, makeStyles } from "@material-ui/core";
import { textFieldColorTheme } from "./commonStyles";
import { blueGray, cancelColor, cobalt, ghostWhite, inactiveColor, lightBlackHover, lightPink, midNightBlue, osloGrey, snowWhite } from "../../theme";
import { TaskViewRepresentation } from "../../../types/taskQueryTypes";

export const tabsTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
        marginLeft: "24px",
      },
      elevation1: {
        boxShadow: "none !important",
      },
    },
    MuiTabs: {
      root: { padding: "12px 0 0px 0", boxShadow: "none" },
    },
    MuiTab: {
      root: {
        fontSize: "24px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "28px !important",
        letterSpacing: "0em !important",
        maxWidth: "fit-content",
      },
      textColorPrimary: {
        "&.Mui-selected": {
          color: cobalt,
        },
      },
    },
  },
});

export const SuccessMessageTheme = (): any =>
  (createMuiTheme as any)({
    overrides: {
      MuiTypography: {
        body1: {
          zIndex: 1000,
          border: "1px solid #4CAF50 !important",
          color: "#4CAF50",
          fontSize: "14px !important",
          fontStyle: "normal",
          lineHeight: "22.6px",
          letterSpacing: "0.15",
          textAlign: "left",
          fontWeight: "500 !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#FFFFFF",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "4px 0px 0px 4px",
          width: "280px",
          height: "72px",
          position: "absolute",
          right: "0",
          margin: "16px 0px 16px 0px",
        },
      },
      MuiSvgIcon: {
        root: {
          fill: "#4CAF50 !important",
          marginLeft: "8px",
          marginTop: "-1em",
          cursor: "pointer",
        },
      },
      MuiGrid: {
        item: {
          paddingTop: "0px",
        },
      },
    },
  });

export const IconButtonTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: `${lightBlackHover} !important`,
        },
        padding: "12px",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        fontWeight: 500,
      },
      tooltipPlacementBottom: { marginTop: "10px !important" },
    },
  },
});
export const inlineStyles = (
  isItMobile: boolean,
  isItTablet: boolean,
  isDrawerOpen: boolean | undefined,
  isItSmallMobile: boolean,
  drawerCloseWidth: boolean
) => ({
  iconColor: {
    fill: "#134A9F",
    color: "#134A9F",
  },
  editIconStyle: {
    color: "rgb(63, 81, 181)",
  },
  display: {
    display: "flex",
    marginRight: "8px",
  },
  successMessage: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical" as any,
    WebkitLineClamp: 2,
    display: "-webkit-box",
  },
  padding: {
    padding: "16px",
  },
  tabs: { paddingBottom: isItMobile ? 16 : 0 },
  tabsWrapper: {
    flexDirection: isItTablet ? (isDrawerOpen ? "column" : drawerCloseWidth ? "column" : "row") : isItMobile ? "column" : ("row" as any),
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: isItMobile ? 16 : 32,
    marginBottom: "24px",
  },
  iconButton: {
    paddingLeft: 0,
  },
});

export const assigneeColumnTheme = createMuiTheme({
  overrides: {
    ...textFieldColorTheme,
  },
});

export const processStarterDialogTheme = createMuiTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        padding: "6px 24px",
      },
    },
    MuiDialogContentText: { root: { marginBottom: 0 } },
    MuiTypography: {
      h6: {
        fontSize: "17px",
        fontWeight: 550,
        lineHeight: "23.44px",
      },
    },
  },
});

export const activeTaskButtonStyles = makeStyles(() => ({
  commonStyle: {
    whiteSpace: "nowrap",
    maxHeight: 52,
    height: 52,
    padding: 16,
    borderRadius: 4,
    border: "none",
    backgroundColor: lightPink,
    textTransform: "none",
    fontFamily: "inter",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "28px",
    "@media (max-width:1030px)": {
      fontSize: 18,
    },
    "@media (max-width:715px)": {
      fontSize: 16,
    },
    "@media (max-width:375px)": {
      fontSize: 16,
    },
  },
}));
export const processNameStyle = {
  textStyle: { color: blueGray, fontFamily: "inter", fontSize: 14, fontWeight: 400, lineHeight: "22px", letterSpacing: 0.14, paddingBottom: 6 },
};
export const tabButtonStyle = (isItMobile: boolean, isItTablet: boolean) => ({
  activeStyle: {
    backgroundColor: snowWhite,
    color: cancelColor,
    border: `2px solid ${cancelColor}`,
  },
  inactiveStyle: {
    backgroundColor: lightPink,
    color: midNightBlue,
    border: "none",
  },
  wrapper: {
    display: "flex",
    gap: 12,
    justifyContent: isItTablet ? "flex-start" : "center",
    backgroundColor: ghostWhite,
    "@media (max-width: 1024px)": {
      justifyContent: "flex-start",
    },
    "@media (max-width: 820px)": {
      justifyContent: "space-between",
    },
  },
});

export const dashboardCustomStyles = makeStyles(theme => ({
  taskEditIcon: {
    padding: "4px",
    borderRadius: "50%",
    "&:hover": {
      background: lightBlackHover,
    },
  },
  paper: {
    backgroundColor: ghostWhite,
    overflowX: "hidden",
    marginTop: "2px",
  },
  paperBackGround: (props: { showTeamDetailView: boolean; teamDetail: TaskViewRepresentation | null | undefined }) => ({
    paddingTop: props.showTeamDetailView && props.teamDetail ? 16 : 0,
    backgroundColor: ghostWhite,
  }),
}));

export const processStarterStyles = makeStyles(theme => ({
  button: {
    alignSelf: "center",
    "@media (max-width:1400px)": {
      alignSelf: "end",
    },
    "@media (max-width:1270px)": {
      alignSelf: "end",
    },
    "@media (max-width:600px)": {
      alignSelf: "auto",
    },
  },
  button1: {
    alignSelf: "center",
    "@media (max-width:1200px)": {
      alignSelf: "end",
    },
    "@media (max-width:600px)": {
      alignSelf: "auto",
    },
  },
  closeIcon: {
    color: osloGrey,
    height: 24,
    width: 24,
  },
  emptyGrid: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "calc(50%)",
  },
  emptyText: {
    color: midNightBlue,
    fontSize: "1rem",
    paddingTop: "1rem",
  },
  header: {
    width: 270,
    color: midNightBlue,
    fontFamily: "inter",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "24px",
    letterSpacing: "1%",
  },
  headerContainer: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingBottom: "24px",
  },
  iconButton: {
    margin: "-6px",
  },
  loader: {
    color: cobalt,
  },
  loaderBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "calc(80%)",
  },
  paper: {
    width: "300px",
    "@media (max-width:325px)": {
      marginRight: "0.6em !important",
    },
  },
}));

export const processStarterTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: 6,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      rounded: {
        borderRadius: "0px",
      },
    },
  },
});

export const chipLabelStyles = {
  label: {
    color: inactiveColor,
    fontFamily: "inter",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "1%",
  },
};
