import { CUT, GRAPES_JS_FORM } from "../TeamTask/constants";

export const formType = (objectDefinition: any) => {
  if (objectDefinition) {
    const { formId, cutFormId } = objectDefinition.otherData;
    if (formId) {
      return GRAPES_JS_FORM;
    } else if (cutFormId) {
      return CUT;
    }
  }
};