import React, { useContext, useEffect, useState } from "react";
import { NotificationsNoneRounded } from "@material-ui/icons";
import NotificationView from "./NotificationView";
import { AccountContext } from "../../../App";
import { ApiExecutor, ApiExecutorState } from "../../../rxjs/ApiExecutor";
import { fetchForNotifications } from "../../../services/TaskService";
import { checkAndUpdateTitle, markNotificationsRead, notify, totalNotifications } from "./util";
import { NotificationParam, TaskNotification } from "../../../types/taskQueryTypes";
import { ApiResult } from "../../../types/commonTypes";
import { EMPTY, SHOW_NOTIFICATION_POPUP, STATUS_CODES, TRUE } from "../../../constants";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next from "i18next";
import { tenantId } from "../../../core/tenantIdentifier";
import NotificationBloc, { NotificationState, defaultNotificationState, updateNotificationBlocState } from "../../../rxjs/notificationBloc";
import { useBloc } from "../../../rxjs/blocBuilder";
import { CANDIDATE_TASKS, MY_TASKS } from "../../tasks/constants";

interface OwnProps extends WithTranslation {
  history: any;
  t: i18next.TFunction;
}

function NotificationComponent({ history, t }: OwnProps) {
  const [notificationResponse, setNotificationResponse] = useState<ApiExecutorState<TaskNotification> | undefined>(undefined);
  const [notification, setNotification] = useState<TaskNotification | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const accountContext = useContext(AccountContext);

  const notificationBloc = NotificationBloc.getInstance();
  const notificationState: any = useBloc<NotificationState>(defaultNotificationState, notificationBloc.getSubject());
  const isNotificationUpdateValue = notificationState ? notificationState.data.isNotificationUpdate : undefined;

  useEffect(() => {
    var apiExecutor = new ApiExecutor<ApiExecutorState<TaskNotification>, NotificationParam>(fetchForNotifications);
    var subscription = apiExecutor.subscribe(setNotificationResponse);
    const userId = accountContext ? accountContext.id : EMPTY;
    apiExecutor.callApi({ userId });
    //call notification api every 30 minutes (1 second = 1000 milliseconds)
    const timer = setInterval(() => apiExecutor.callApi({ userId }), 1800000);

    return () => {
      checkAndUpdateTitle(0);
      clearInterval(timer);
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (isNotificationUpdateValue) {
      var apiExecutor = new ApiExecutor<ApiExecutorState<TaskNotification>, NotificationParam>(fetchForNotifications);
      apiExecutor.subscribe(setNotificationResponse);
      const userId = accountContext ? accountContext.id : EMPTY;
      apiExecutor.callApi({ userId });
      updateNotificationBlocState({ isNotificationUpdate: false });
    }
  }, [isNotificationUpdateValue]);

  useEffect(() => {
    if (notificationResponse && notificationResponse.status === STATUS_CODES.SUCCESS) {
      const NOTIFICATION = `${tenantId()}-${SHOW_NOTIFICATION_POPUP}`;
      const taskNotification = (notificationResponse.pagedApiResult as ApiResult<TaskNotification>).result;
      setNotification(taskNotification);
      if (localStorage.getItem(NOTIFICATION) === TRUE) {
        handleOpen();
        localStorage.removeItem(NOTIFICATION);
      } else {
        notify(taskNotification, t);
      }
    }
  }, [notificationResponse]);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const markAssignedTaskRead = () => {
    notification && markNotificationsRead(setNotification, history, notification, MY_TASKS, true);
    handleClose();
  };

  const markCandidateTaskRead = () => {
    notification && markNotificationsRead(setNotification, history, notification, CANDIDATE_TASKS);
    handleClose();
  };

  return notification ? (
    <NotificationView
      count={totalNotifications(notification)}
      notification={notification}
      onClickAssignedTask={markAssignedTaskRead}
      onClickCandidateTask={markCandidateTaskRead}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    />
  ) : (
    <NotificationsNoneRounded />
  );
}

export default withTranslation()(NotificationComponent);
