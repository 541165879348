/* globals  */
import React, { useContext, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";

import GoogleAnalytics from "./components/GoogleAnalytics";
import Login from "./views/idm/Login";
import AuthenticatedContainer from "./views/idm/AuthenticatedContainer";
import Master from "./views/layout/Master";
import Dashboard from "./views/Dashboard";
import ProcessEngineView from "./views/process/ProcessEngineView";
import Home from "./views/layout/Home";
import TaskEngineView from "./views/tasks/TaskEngineView";
import "./App.scss";
import ChangePassword from "./views/passwordManagement/ChangePassword";
import ResetPassword from "./views/passwordManagement/ResetPassword";

import Report from "./views/reports/Report";
import { Account } from "./redux/types";
import { Alert } from "./redux/reducers/alertReducer";
import { StylesProvider, createGenerateClassName } from "@material-ui/styles";
import history from "./core/history";
import ObjectDataList from "./views/reports/ObjectDataList";
import { Notifications } from "react-push-notification";
import { OAuthCallBackComponent } from "./views/common/OAuthCallBackComponent";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {
  //
}

interface StateProps {
  alerts: Alert[];
}

interface DispatchProps {
  setAlertShown: (ref: string) => void;
  removeAlerts: () => void;
}

export const AccountContext = React.createContext<Account | undefined>(undefined); // { account: {}, isAuthenticated: false }
export const LogoutContext = React.createContext<any>(undefined);

export const accountContext = () => useContext(AccountContext);

function App(props: OwnProps & StateProps & DispatchProps): any {
  const [account, setAccount] = useState(undefined);

  function updateAcccount(accountObj: any): void {
    setAccount(accountObj);
  }

  const generateClassName = createGenerateClassName({
    productionPrefix: "juakali",
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var accountInContext: any = account ? account : null;
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Notifications />
      <Router history={history}>
        <GoogleAnalytics>
            <AccountContext.Provider value={account}>
              <Switch>
                {/* <Route exact path='/' component={Dashboard} /> */}
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/accounts/password/reset" component={ResetPassword} />
                <Route exact path="/accounts/password/change" component={ChangePassword} />
                <AuthenticatedContainer updateAcccount={updateAcccount}>
                  {accountInContext && accountInContext.id && (
                    <Master updateAcccount={updateAcccount}>
                      <Home>
                        <Switch>
                          <Route exact path="/" component={Dashboard} />
                          {/* <Route exact path="/userSettings" component={UserSettings} /> */}
                          <Route path="/process/:type/:appDefinitionKey/new" component={ProcessEngineView} />
                          <Route path="/oauth/callback" component={OAuthCallBackComponent} />
                          <Route path="/process/task/:taskId" component={TaskEngineView} />
                          <Route path="/process/instance/:processInstanceId" component={TaskEngineView} />

                          <Route path="/report/:type/:detailId" component={Report} />
                          <Route path="/report/:type" component={Report} />
                          <Route exact path="/objectdata/:key/:version" component={ObjectDataList} />
                          <Route exact path="/objectdata/:key/:version/:recordId" component={ObjectDataList} />
                        </Switch>
                      </Home>
                    </Master>
                  )}
                </AuthenticatedContainer>
              </Switch>
            </AccountContext.Provider>
        </GoogleAnalytics>
      </Router>
    </StylesProvider>
  );
}

export default Sentry.withProfiler(App);