import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next";
import { WithTranslation, withTranslation } from "react-i18next";
import { IconButton, InputAdornment, TextField, useMediaQuery } from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { searchIconStyles, searchStyles } from "../styles/toolbarStyles";
import { TaskQueryFilterParams } from "../../../types/taskQueryTypes";
import { cobalt } from "../../theme";
import { EMPTY } from "../../../constants";
import { useResponsiveQueries } from "../../../util/helper";

interface OwnPropsA {
  applyFilter: (params?: TaskQueryFilterParams) => void;
  assignment: string;
  defaultFilter?: string;
  isMobileView: boolean;
}

interface OwnPropsB extends WithTranslation {
  t: i18n.TFunction;
}

type OwnProps = OwnPropsA & OwnPropsB;
const SearchFilter: React.FC<OwnProps> = ({ applyFilter, assignment, defaultFilter = EMPTY, isMobileView, t }) => {
  const [searchClicked, setSearchClicked] = useState(false);
  const [taskName, setTaskName] = useState(defaultFilter);
  const textInput = useRef<HTMLInputElement>(null);
  const classes = searchStyles();
  const searchIconStyle = searchIconStyles();
  const { isItTablet: isItSmallDesktop } = useResponsiveQueries();
  const isItTablet = useMediaQuery("(max-width:950px)");
  const isItMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    setTaskName(EMPTY);
    setSearchClicked(false);
  }, [assignment]);

  const handleSearch = () => {
    const taskNameAppliedInFilters = searchClicked ? EMPTY : taskName;
    setSearchClicked(!taskName ? false : !searchClicked);
    applyFilter({ taskName: taskNameAppliedInFilters });
  };

  const handleClear = () => {
    setTaskName(EMPTY);
    setSearchClicked(false);
    applyFilter({ taskName: EMPTY });
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      textInput && textInput.current && textInput.current.blur();
      handleSearch();
    }
  };
  const getSearchFieldWidth = () => {
    if (isItTablet) return "40vw";
    if (isItSmallDesktop) return "30vw";

    return "40vw";
  };
  const searchFieldStyle = {
    width: getSearchFieldWidth(),
    ...(isItMobile && { flex: 1 }),
  };
  return (
    <TextField
      data-testid="searchTextField"
      inputRef={textInput}
      id={`${assignment}-search`}
      name={`${assignment}-search`}
      placeholder={t(`dashboard.taskNameFilterLabel`)}
      variant="outlined"
      onChange={e => setTaskName(e.target.value)}
      onFocus={() => setSearchClicked(false)}
      onKeyDown={onPressEnter}
      value={taskName}
      style={searchFieldStyle}
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
          focused: classes.focused,
          adornedStart: classes.adornedStart,
          adornedEnd: classes.adornedEnd,
        },
        startAdornment: (
          <InputAdornment position="start">
            <IconButton aria-label="search" onClick={handleSearch} classes={{ root: searchIconStyle.iconButton }} data-testid="search">
              <SearchRoundedIcon htmlColor={cobalt} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: searchClicked && (
          <InputAdornment position="end">
            <IconButton aria-label="clear" onClick={handleClear} classes={{ root: searchIconStyle.iconButton }}>
              <ClearRoundedIcon htmlColor={cobalt} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default withTranslation()(SearchFilter);
