import i18n from "../i18n";
import { showNotification } from "../util/notification";

export const getIAPEmail = async () => {
  try {
    const response = await fetch("/oauth/status", {
      method: "GET",
      redirect: "manual",
    });
    const { headers } = response;
    if (response.ok && headers) {
      const email = headers.get("X-Goog-Authenticated-User-Email");
      return email && email.includes("accounts.google.com") ? email.split(":")[1] : null;
    } else if (!response.ok || response.status === 0) {
      return null;
    } else {
      throw new Error();
    }
  } catch (e) {
    showNotification("error", i18n.t("common.serverErrorTryAgain"));
  }
};
