/* eslint-disable react/display-name */
// @flow
import { createMuiTheme, MuiThemeProvider, Paper, Typography, Grid as GridItem, Button } from "@material-ui/core";
import { Grid, Row, Cell } from "@material/react-layout-grid";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState, useContext } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ConfirmationDialog, { ConfirmationDialogProps } from "../common/ConfirmationDialog";
import ForwardIcon from "../common/ForwardIcon";
import TeamTaskDetailView from "../TeamTask/TeamTaskDetailView";
import { showNotification } from "../../util/notification";
import { defaultDownloadUserRequest, defaultPaging, JuakaliUser } from "../../redux/types";
import { fetchWrapper } from "../../core/fetchWrapper";
import { fetchForTaskDetail, canClaimTask } from "../../services/TaskService";
import { AccountContext } from "../../App";
import { claimTaskCall } from "../../services/flow/FTaskService";
import {
  TaskState,
  TaskAssignment,
  SortType,
  TaskTableType,
  sortTypeAndColumnMapper,
  DashboardStateProps,
  DashboardDispatchProps,
  QuickFilter,
} from "../../types/taskQueryTypes";
import { DynamicJson } from "../../datastore/dataStoreTypes";
import {
  IconButtonTheme,
  SuccessMessageTheme,
  tabsTheme,
  inlineStyles,
  dashboardCustomStyles,
  activeTaskButtonStyles,
  tabButtonStyle,
} from "./styles/dashboardStyles";
import {
  defaultConfirmationPropertiesOnCancel,
  defaultConfirmationPropertiesOnOtherAction,
  getBodyText,
  getCurrentAssignee,
  getMappedTasks,
  markTaskStarted,
} from "./utils/dashboardHelper";
import {
  CANCEL,
  SAVE,
  TEAMVIEW,
  CHANGEASSIGNEE,
  EDIT_PROCESS_INSTANCE,
  PROCESSINSTANCE,
  REASSIGN_SUCCESS,
  REASSIGN_ERROR,
  EMPTY,
} from "../../constants";
import { getRowsPerPageSize, useResponsiveQueries } from "../../util/helper";
import { TableKeys } from "../../constants/index";
import NotificationBloc, { updateNotificationBlocState } from "../../rxjs/notificationBloc";
import { mapDispatchToProps, mapStateToProps } from "./utils/mapProps";
import { cancelColor, cobalt, midNightBlue } from "../theme";
import ProcessStarter from "./ProcessStarter";
import { updateFormState } from "../../rxjs/formBloc";
import { Edit } from "@material-ui/icons";
import { CANDIDATE_TASKS, MY_TASKS, NEW, TEAM_TASKS } from "../tasks/constants";
import IndividualIcon from "../common/IndividualIcon";
import GroupIcon from "../common/GroupIcon";
import TeamIcon from "../common/TeamIcon";
import CommonTable from "./Table/CommonTable";
import { getTaskTableColumnProperties } from "./TaskTable";
import CandidateAssignIcon from "../common/CandidateAssignIcon";
import { CommonToolTip } from "./styles/commonStyles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps<{}> {
  history: any;
}

function Dashboard(props: OwnProps & DashboardStateProps & DashboardDispatchProps): JSX.Element {
  const { t } = useTranslation(["translation", "flow"]);
  /* get currently logged in user account */
  const account = useContext(AccountContext);
  /* set loading state for fetch task and claim api calls */
  const [activeTask, setActiveTask] = useState(MY_TASKS);
  const [isLoading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentTeamTask, setCurrentTeamTask] = useState<any[] | null>(null);
  const [assignee, setAssignee] = useState<JuakaliUser | undefined>(undefined);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showTeamDetailView, setTeamDetailView] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<ConfirmationDialogProps>(defaultConfirmationPropertiesOnCancel());
  /* get notification status from rxjs store */
  const notificationStateBloc = NotificationBloc.getInstance();
  let notificationState = notificationStateBloc.getSubject();
  let { isAssignedTaskSelected, typeofNotification } = notificationState.value;
  const [filterNewAssignedTask, setFilterNewAssignedTask] = useState<boolean>(isAssignedTaskSelected);
  const theme = createMuiTheme();
  const isItSmallMobile = useMediaQuery("(max-width: 600px)");
  const isItMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { isItTablet, drawerCloseWidth } = useResponsiveQueries();
  const [activeQuickFilter, setActiveQuickFilter] = useState<QuickFilter>(QuickFilter.ALL);
  let {
    candidateTasksStatus,
    involvedTasksStatus,
    assignedTaskQueryParams,
    candidateTaskQueryParams,
    teamTaskQueryParams,
    usersList,
    fetchTasks,
    fetchUsers,
    reassignTask,
    teamTasksStatus,
    drawerStatus,
  } = props;
  const { TEAM_TASK_LISTS, CANDIDATE_TASK_LISTS, ASSIGNED_TASK_LISTS } = TableKeys;
  const activeButtonClasses = activeTaskButtonStyles();
  let { iconColor, successMessage, padding, tabsWrapper, editIconStyle, display } = inlineStyles(
    isItMobile,
    isItTablet,
    drawerStatus || false,
    isItSmallMobile,
    drawerCloseWidth
  );
  let teamDetail = currentTeamTask && props.teamTasks.find(t => t.id === currentTeamTask[2]);
  const { paper, paperBackGround } = dashboardCustomStyles({ showTeamDetailView, teamDetail });
  const { wrapper, activeStyle, inactiveStyle } = tabButtonStyle(isItMobile, isItTablet);
  if (isAssignedTaskSelected) {
    currentTab && setCurrentTab(0);
    !filterNewAssignedTask && setFilterNewAssignedTask(true);
    if (typeofNotification === MY_TASKS) {
      handleTaskQuickFilter(TaskAssignment.INVOLVED, QuickFilter.NEW);
      setActiveTask(MY_TASKS);
    } else if (typeofNotification === CANDIDATE_TASKS) {
      handleTaskQuickFilter(TaskAssignment.CANDIDATE, QuickFilter.ALL);
      setActiveTask(CANDIDATE_TASKS);
    }
    updateNotificationBlocState({ isAssignedTaskSelected: false });
  }

  const involvedQueryParams = {
    assignment: TaskAssignment.INVOLVED,
    sortType: SortType.DUE_DATE_ASCENDING,
    state: TaskState.OPEN,
    page: defaultPaging.page,
    size: getRowsPerPageSize(ASSIGNED_TASK_LISTS),
    filterNew: filterNewAssignedTask,
  };

  const candidateQueryParams = {
    assignment: TaskAssignment.CANDIDATE,
    sortType: SortType.DUE_DATE_ASCENDING,
    state: TaskState.OPEN,
    page: defaultPaging.page,
    size: getRowsPerPageSize(CANDIDATE_TASK_LISTS),
  };

  const teamQueryParams = {
    sortType: SortType.DUE_DATE_ASCENDING,
    page: defaultPaging.page,
    size: getRowsPerPageSize(TEAM_TASK_LISTS),
  };

  useEffect(() => {
    if (activeTask === MY_TASKS) {
      fetchTasks(involvedQueryParams);
      setCurrentTeamTask(null);
    }
    if (activeTask === CANDIDATE_TASKS) {
      fetchTasks(candidateQueryParams);
      setCurrentTeamTask(null);
    }
    if (activeTask === TEAM_TASKS) {
      filterNewAssignedTask && setFilterNewAssignedTask(false);
      fetchUsers(defaultDownloadUserRequest);
      fetchTasks(teamQueryParams);
    }
    setTeamDetailView(false);
  }, [activeTask]);

  useEffect(() => {
    fetchTasks(candidateQueryParams);
    fetchTasks(teamQueryParams);
  }, []);

  useEffect(() => {
    const isTeamTasksStatus = teamTasksStatus === REASSIGN_SUCCESS;
    const isCandidateTasksStatus = candidateTasksStatus === REASSIGN_SUCCESS;
    if (isTeamTasksStatus || isCandidateTasksStatus) {
      /* set showSuccessMessage state to `true` when reassignment is succeed  */
      setShowSuccessMessage(true);
      /* showSuccessMessage state automatically reset to false after 7 seconds  */
      setTimeout((): void => {
        setShowSuccessMessage(false);
        updateFormState({ isLoading: false });
      }, 7000);
      setCurrentTeamTask(null);
      setAssignee(undefined);
      fetchTasks(props.teamTaskQueryParams);
      fetchTasks(involvedQueryParams);
      filterNewAssignedTask && setFilterNewAssignedTask(false);
    } else if (teamTasksStatus === REASSIGN_ERROR || candidateTasksStatus === REASSIGN_ERROR) {
      /* scroll to the top of dashboard page when re-assignment failed */
      let mdcDrawerContent = (document as HTMLDocument).querySelector(".mdc-drawer-app-content") as HTMLElement;
      mdcDrawerContent && mdcDrawerContent.scroll(0, 0);
    }
  }, [teamTasksStatus, candidateTasksStatus]);

  useEffect(() => {
    /* team tasks fetched when we go to team task table screen from team task detail view */
    if (!showTeamDetailView && currentTab == 1) {
      fetchTasks(props.teamTaskQueryParams);
    }
  }, [showTeamDetailView]);

  useEffect(() => {
    updateNotificationBlocState({ isNotificationUpdate: true });
    return () => {
      props.resetTasks();
    };
  }, []);

  function handleTaskQuickFilter(taskAssignment: TaskAssignment, filterType: QuickFilter) {
    setActiveQuickFilter(filterType);
    const queryParams = { page: defaultPaging.page, quickFilterType: filterType, filterNew: filterType === QuickFilter.NEW };

    switch (taskAssignment) {
      case TaskAssignment.TEAM:
        fetchTasks({ ...teamTaskQueryParams, ...queryParams });
        break;
      case TaskAssignment.INVOLVED:
        fetchTasks({ ...assignedTaskQueryParams, ...queryParams });
        break;
      case TaskAssignment.CANDIDATE:
        fetchTasks({ ...candidateTaskQueryParams, ...queryParams });
        break;
    }
  }

  function claimCall(taskId: string): any {
    /* set loading status to 'true' when fetch task call starts */
    setLoading(true);
    var promise = fetchWrapper(fetchForTaskDetail, taskId);
    promise
      .then((res: any) => {
        if (res.status === 200) {
          return res.json();
        } else {
          if (res.status !== 401) throw new Error(res.status);
        }
      })
      .then((result: any) => {
        if (canClaimTask(result, account)) {
          var promise = fetchWrapper(claimTaskCall, result.id);
          promise
            .then((claimResponse: any) => {
              if (claimResponse.status === 200) {
                markTaskStarted(taskId);
                /* set loading status to 'false' when fetch task and claim call ends */
                setLoading(false);
                // path redirection to `process/task/{taskId}`
                props.history.push(`process/task/${taskId}`);
              } else {
                if (claimResponse.status !== 401) throw new Error(claimResponse.status);
              }
            })
            .catch((err: any) => {
              /* set loading status to 'false' if any problem with server */
              setLoading(false);
              // show error snackbar if any problem with server after the api call triggered
              if (err.message && err.message != 401 && err.message != 403) showNotification("error", t("common.serverErrorTryAgain"));
            });
        } else {
          /* set loading status to 'false' when the task has been assinged already */
          setLoading(false);
          // Show error snackbar whenever task already has assignee
          showNotification("error", t("common.insufficientPriviliges"));
        }
      })
      .catch((e: any) => {
        /* set loading status to 'false' if any problem with server */
        setLoading(false);
        // show error snackbar if any problem with server after the api call triggered
        if (e.message && e.message != 401 && e.message != 403) showNotification("error", t("common.serverErrorTryAgain"));
      });
  }

  const onEditIconClick = (tableMeta: any, taskTableType: TaskTableType) => {
    const { history } = props;
    ReactGA.event({
      category: PROCESSINSTANCE,
      action: EDIT_PROCESS_INSTANCE,
      label: EDIT_PROCESS_INSTANCE,
    });
    let { rowData } = tableMeta;
    if (rowData && rowData.length) {
      if (taskTableType === TaskTableType.CANDIDATE) {
        /* claim the unassigned tasks with the currently logged in user */
        claimCall(rowData[1]);
      } else {
        const isNewTask = rowData[2].split(",")[1] === NEW;
        isNewTask && markTaskStarted(rowData[1]);
        history.push(`process/task/${rowData[1]}`);
      }
    }
  };

  const getIconButton = (buttonType: string, tableMeta: any): JSX.Element => {
    const { rowIndex, tableState } = tableMeta;
    const { rowsSelected } = tableState;
    const disableIcon = !!(rowsSelected && rowsSelected.includes(rowIndex));

    let iconButton;
    let handleClickIconButton = (): void => {
      if (currentTeamTask) {
        let fromUser = (getCurrentAssignee(currentTeamTask[10], usersList, true) as string | undefined) || "";
        setConfirmationDialogProps({
          open: true,
          title: t("dashboard.reassign"),
          content: t("dashboard.reassignTasksContent", { Assignee: assignee ? assignee.firstName : currentTeamTask[7] }),
          primaryLabel: t("common.confirm"),
          secondaryLabel: t("common.cancel"),
          primaryHandler: () => {
            reassignTask({
              fromUser: fromUser,
              toUser: assignee ? assignee.email : fromUser,
              taskId: currentTeamTask[2],
            });
            setConfirmationDialogProps(defaultConfirmationPropertiesOnCancel());
          },
          secondaryHandler: () => {
            setConfirmationDialogProps(defaultConfirmationPropertiesOnCancel());
          },
        });
      }
    };
    switch (buttonType) {
      case SAVE:
        iconButton = <DoneIcon style={iconColor} />;
        break;
      case CANCEL:
        iconButton = <ClearIcon style={iconColor} />;
        handleClickIconButton = (): void => {
          setConfirmationDialogProps({
            ...defaultConfirmationPropertiesOnCancel(),
            open: true,
            primaryHandler: () => {
              setAssignee(undefined);
              setCurrentTeamTask(null);
              setConfirmationDialogProps(defaultConfirmationPropertiesOnCancel());
            },
            secondaryHandler: () => {
              setConfirmationDialogProps(defaultConfirmationPropertiesOnCancel());
            },
          });
        };
        break;
      case CHANGEASSIGNEE:
      case TEAMVIEW:
        const commonProps = { htmlColor: cobalt, opacity: disableIcon ? 0.5 : 1 };
        iconButton = buttonType === CHANGEASSIGNEE ? <ForwardIcon {...commonProps} /> : <VisibilityIcon {...commonProps} />;
        handleClickIconButton = (): void => {
          if (currentTeamTask) {
            setConfirmationDialogProps({
              ...defaultConfirmationPropertiesOnOtherAction(),
              open: true,
              content: t("confirmation.unsavedChangesContent", { name: currentTeamTask[5] }),
              primaryHandler: () => {
                buttonType === CHANGEASSIGNEE ? setAssignee(undefined) : setTeamDetailView(true);
                setCurrentTeamTask(tableMeta.rowData);
                setConfirmationDialogProps(defaultConfirmationPropertiesOnOtherAction());
              },
              secondaryHandler: () => {
                setConfirmationDialogProps(defaultConfirmationPropertiesOnOtherAction());
              },
            });
          } else {
            buttonType === CHANGEASSIGNEE ? setAssignee(undefined) : setTeamDetailView(true);
            setCurrentTeamTask(tableMeta.rowData);
          }
        };
        break;
    }
    return (
      <MuiThemeProvider theme={IconButtonTheme}>
        <CommonToolTip title={t(`${buttonType === CHANGEASSIGNEE || buttonType === TEAMVIEW ? `dashboard` : `common`}.${buttonType}`) as string}>
          <IconButton disabled={disableIcon} onClick={handleClickIconButton}>
            {iconButton}
          </IconButton>
        </CommonToolTip>
      </MuiThemeProvider>
    );
  };

  const getEditColumn = (taskTableType: TaskTableType): any => {
    return {
      name: "Actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value: any, tableMeta: any): any => {
          return taskTableType === TaskTableType.TEAM ? (
            <>
              {currentTeamTask && currentTeamTask[1] === tableMeta.rowData[1] ? (
                <div style={display}>
                  {getIconButton(SAVE, tableMeta)}
                  {getIconButton(CANCEL, tableMeta)}
                </div>
              ) : (
                <div style={display}>
                  {getIconButton(CHANGEASSIGNEE, tableMeta)}
                  {getIconButton(TEAMVIEW, tableMeta)}
                </div>
              )}
            </>
          ) : (
            <CommonToolTip title={t(`common.${taskTableType === TaskTableType.CANDIDATE ? "claim" : "edit"}`) as string}>
              {taskTableType === TaskTableType.CANDIDATE ? (
                <IconButton onClick={() => onEditIconClick(tableMeta, taskTableType)}>
                  <CandidateAssignIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => onEditIconClick(tableMeta, taskTableType)}>
                  <Edit style={editIconStyle} />
                </IconButton>
              )}
            </CommonToolTip>
          );
        },
      },
    };
  };

  /* Header for Assigned/Candidate tasks list */
  const tasksHeader = (taskTableType: TaskTableType): any => {
    var queryParams =
      taskTableType === TaskTableType.CANDIDATE
        ? props.candidateTaskQueryParams
        : taskTableType === TaskTableType.INVOLVED
        ? props.assignedTaskQueryParams
        : props.teamTaskQueryParams;
    if (!queryParams) {
      return [];
    }
    var columnArray = [];
    // build the columns for task table
    let propertiesForTeamTask =
      taskTableType === TaskTableType.TEAM
        ? {
            currentTeamTask,
            usersList,
            setAssignee,
            assignee,
          }
        : {};
    let TaskTableColumnProperties = getTaskTableColumnProperties(taskTableType, propertiesForTeamTask);
    Object.keys(TaskTableColumnProperties).forEach((key: string) => {
      var columnProperties = TaskTableColumnProperties[key];
      if (columnProperties) {
        let { isShownInTeamTable, isShownInCandidateTable, isShownInInvolvedTable } = columnProperties;

        /**
         * Skip if the following conditions occured
         * - if the current view is mobile and the current column is not the one for mobile
         * - if the current column need not be shown in team table
         */
        if (taskTableType === TaskTableType.TEAM) {
          let { isVisibleInMobile, isVisibleInDesktop } = isShownInTeamTable;
          if (isItMobile ? !isVisibleInMobile : !isVisibleInDesktop) {
            columnProperties.display = "excluded";
          } else {
            columnProperties.display = columnProperties.displayParam !== "excluded" ? true : "excluded";
          }
        }
        /**
         * Skip if the following conditions occured
         * - if the current view is mobile and the current column is not the one for mobile
         * - if the current column need not be shown in candidate table
         */
        if (taskTableType === TaskTableType.CANDIDATE) {
          let { isVisibleInMobile, isVisibleInDesktop } = isShownInCandidateTable;
          if (isItMobile ? !isVisibleInMobile : !isVisibleInDesktop) {
            columnProperties.display = "excluded";
          } else {
            columnProperties.display = columnProperties.displayParam !== "excluded" ? true : "excluded";
          }
        }
        /**
         * Skip if the following conditions occured
         * - if the current view is mobile and the current column is not the one for mobile
         * - if the current column need not be shown in involved table
         */
        if (taskTableType === TaskTableType.INVOLVED) {
          let { isVisibleInMobile, isVisibleInDesktop } = isShownInInvolvedTable;
          if (isItMobile ? !isVisibleInMobile : !isVisibleInDesktop) {
            columnProperties.display = "excluded";
          } else {
            columnProperties.display = columnProperties.displayParam !== "excluded" ? true : "excluded";
          }
        }
        var muiColumnDefinition: DynamicJson = { name: columnProperties.name, label: t(`tasks.${columnProperties.name}`) };

        //build the options like sort, display for each column
        var options: DynamicJson = {};
        columnProperties.display ? (options.display = columnProperties.display) : null;
        options.sort = columnProperties.sort;
        if (options.sort) {
          //find out and assign sort direction if the current column is the one sorted currently
          options.sortDirection =
            sortTypeAndColumnMapper[queryParams.sortType].columnName === muiColumnDefinition.name
              ? sortTypeAndColumnMapper[queryParams.sortType].sortDirection
              : "none";
        }

        columnProperties.setCellHeaderProps ? (options.setCellHeaderProps = columnProperties.setCellHeaderProps) : null;
        columnProperties.customBodyRender ? (options.customBodyRender = columnProperties.customBodyRender) : null;

        muiColumnDefinition.options = options;
        columnArray.push(muiColumnDefinition);
      }
    });
    let actions = getEditColumn(taskTableType);
    columnArray.push(actions);
    return columnArray;
  };

  const taskMappings: any = {
    [MY_TASKS]: {
      tasks: getMappedTasks(props.pendingTasks),
      pagination: props.involvedTasksPaging,
      isLoading: props.involvedTasksLoading,
      tasksHeader: tasksHeader(TaskTableType.INVOLVED),
      assignment: TaskAssignment.INVOLVED,
      status: involvedTasksStatus,
      taskQueryParams: assignedTaskQueryParams,
      noItemsText: getBodyText(assignedTaskQueryParams),
      onTaskQuickFilter: (filterType: any) => handleTaskQuickFilter(TaskAssignment.INVOLVED, filterType),
      fetchTasks: fetchTasks,
      setStatus: props.setStatus,
      title: EMPTY,
      filterNewAssignedTask: filterNewAssignedTask,
    },
    [CANDIDATE_TASKS]: {
      tasks: getMappedTasks(props.candidateTasks),
      pagination: props.candidateTasksPaging,
      isLoading: props.candidateTasksLoading || isLoading,
      tasksHeader: tasksHeader(TaskTableType.CANDIDATE),
      assignment: TaskAssignment.CANDIDATE,
      status: candidateTasksStatus,
      taskQueryParams: candidateTaskQueryParams,
      noItemsText: getBodyText(candidateQueryParams),
      onTaskQuickFilter: (filterType: any) => handleTaskQuickFilter(TaskAssignment.CANDIDATE, filterType),
      fetchTasks: fetchTasks,
      setStatus: props.setStatus,
      title: EMPTY,
      filterNewAssignedTask: filterNewAssignedTask,
    },
    [TEAM_TASKS]: {
      tasks: getMappedTasks(props.teamTasks),
      pagination: props.teamTasksPaging,
      isLoading: props.teamTasksLoading,
      tasksHeader: tasksHeader(TaskTableType.TEAM),
      assignment: TaskAssignment.TEAM,
      status: props.teamTasksStatus,
      taskQueryParams: teamTaskQueryParams,
      noItemsText: getBodyText(props.teamTaskQueryParams),
      onTaskQuickFilter: (filterType: any) => handleTaskQuickFilter(TaskAssignment.TEAM, filterType),
      fetchTasks: fetchTasks,
      setStatus: props.setStatus,
      title: EMPTY,
      filterNewAssignedTask: filterNewAssignedTask,
    },
  };
  const currentTaskData = taskMappings[activeTask];

  const handleActiveTaskSelection = (task: string) => {
    setActiveTask(task);
    setActiveQuickFilter(QuickFilter.ALL);
  };
  function getIconColor({ activeTask, taskType }: { activeTask: string; taskType: string }): string {
    return activeTask === taskType ? cancelColor : midNightBlue;
  }
  return (
    <Paper className={`dashboardPaper ${paper}`}>
      <MuiThemeProvider theme={tabsTheme}>
        <MuiThemeProvider theme={SuccessMessageTheme()}>
          <GridItem item xs={6} md={6} lg={6} className="float-right">
            {showSuccessMessage ? (
              <div data-testid="successMessage">
                <Typography variant="body1">
                  <span style={{ ...successMessage, ...padding }}>{t("dashboard.savedSuccessfully")}</span>
                  <ClearIcon
                    style={padding}
                    onClick={() => {
                      setShowSuccessMessage(false);
                    }}
                    data-testid="clearIconSuccess"
                  />
                </Typography>
              </div>
            ) : null}
          </GridItem>
        </MuiThemeProvider>
        <Paper className={`${isItMobile || isItTablet ? "mx-1" : "mxf-3"} ${paperBackGround}`} square>
          <div style={tabsWrapper}>
            {/* Tabs */}
            <div aria-label="task buttons" style={wrapper} className="dashboardtab">
              <Button
                id="tabsButton"
                variant="outlined"
                className={activeButtonClasses.commonStyle}
                style={activeTask === MY_TASKS ? activeStyle : inactiveStyle}
                onClick={() => handleActiveTaskSelection(MY_TASKS)}
              >
                <IndividualIcon color={getIconColor({ activeTask, taskType: MY_TASKS })} />
                {`${t("tasks.assignedTasks")} (${props.involvedTasksPaging.totalHeadCount})`}
              </Button>
              <Button
                id="tabsButton"
                variant="outlined"
                className={activeButtonClasses.commonStyle}
                style={activeTask === CANDIDATE_TASKS ? activeStyle : inactiveStyle}
                onClick={() => handleActiveTaskSelection(CANDIDATE_TASKS)}
              >
                <GroupIcon color={getIconColor({ activeTask, taskType: CANDIDATE_TASKS })} />
                {`${t("tasks.candidateTasks")} (${props.candidateTasksPaging.totalHeadCount})`}
              </Button>
              <Button
                id="tabsButton"
                variant="outlined"
                className={activeButtonClasses.commonStyle}
                style={activeTask === TEAM_TASKS ? activeStyle : inactiveStyle}
                onClick={() => handleActiveTaskSelection(TEAM_TASKS)}
              >
                <TeamIcon color={getIconColor({ activeTask, taskType: TEAM_TASKS })} />
                {`${t("tasks.teamTasks")} (${props.teamTasksPaging.totalHeadCount})`}
              </Button>
            </div>
            <ProcessStarter drawerStatus={drawerStatus || false} />
          </div>
        </Paper>
      </MuiThemeProvider>

      <Grid id="main" className={paperBackGround}>
        <Row>
          <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
            {showTeamDetailView && teamDetail ? (
              <TeamTaskDetailView
                setTeamDetailView={setTeamDetailView}
                currentTeamTaskParams={{ currentTeamTask, setCurrentTeamTask }}
                usersList={usersList}
                reassignTask={reassignTask}
                assigneeParams={{ assignee, setAssignee }}
                statusParams={{ status: props.teamTasksStatus, setStatus: props.setStatus }}
                teamTask={teamDetail}
                fetchTasks={fetchTasks}
              />
            ) : (
              <CommonTable
                tasks={currentTaskData.tasks}
                pagination={currentTaskData.pagination}
                isLoading={currentTaskData.isLoading}
                tasksHeader={currentTaskData.tasksHeader}
                fetchTasks={fetchTasks}
                setStatus={props.setStatus}
                assignment={currentTaskData.assignment}
                title={EMPTY}
                status={currentTaskData.status}
                taskQueryParams={currentTaskData.taskQueryParams}
                noItemsText={currentTaskData.noItemsText}
                filterNewAssignedTask={filterNewAssignedTask}
                onTaskQuickFilter={currentTaskData.onTaskQuickFilter}
                activeQuickFilter={activeQuickFilter}
                users={usersList}
                drawerStatus={props.drawerStatus}
              />
            )}
          </Cell>
        </Row>
      </Grid>
      <ConfirmationDialog {...confirmationDialogProps} />
    </Paper>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
