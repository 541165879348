/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "./Header.scss";

import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// import Settings from "@material-ui/icons/Settings";
import fetch from "isomorphic-fetch";
import { showNotification } from "../../util/notification";
import { AccountContext } from "../../App";
import { url } from "../../services/apiConfig";
import { fetchWrapper } from "../../core/fetchWrapper";
import { defaultLoginState, updateLogInBlocState } from "../../rxjs/loginBloc";
import Notification from "./notification";
import LogoutView from "./Logout";
import { Account } from "../../redux/types";
import ConfirmationDialog, { ConfirmationDialogProps } from "../common/ConfirmationDialog";
import i18n from "../../i18n";
import { resetTasks } from "../../redux/reducers/openTasksReducer";
import { DASHBOARD_PATH } from "../TeamTask/constants";
import JuakaliIcon from "../common/JuakaliIcon";
import { headerPosition, headerStyle } from "./styles";
import { CSSProperties } from "@material-ui/styles";
import HamburgerMenuIcon from "../common/HamBurgerMenuIcon";

export const defaultConfirmationPropertiesOnLogOut = (): ConfirmationDialogProps => ({
  open: false,
  title: i18n.t("translation:header.logoutFrom"),
  content: i18n.t("translation:header.logoutConfirmation"),
  primaryLabel: i18n.t("translation:common.yes"),
  secondaryLabel: i18n.t("translation:common.no"),
});

class Header extends Component<any, any, any> {
  accountContext: React.Context<Account | undefined>;
  logoutFunction: () => Promise<Response>;
  constructor(props: any) {
    super(props);
    this.accountContext = AccountContext;
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.logoutFunction = () => {
      return fetch(url.logout);
    };
    this.state = {
      isLogoutPopupOpen: false,
      confirmationDialogProps: defaultConfirmationPropertiesOnLogOut(),
    };
  }
  toggleDrawer() {
    if (this.props.toggleDrawer) {
      this.props.toggleDrawer();
    }
  }

  handleClick = () => {
    this.setState((prevState: any) => ({ isLogoutPopupOpen: !prevState.isLogoutPopupOpen }));
  };
  handleClose = () => {
    this.setState((prevState: any) => ({ isLogoutPopupOpen: false }));
  };

  handleLogout(account: any) {
    if (!this.state.confirmationDialogProps.open)
      this.setState({
        confirmationDialogProps: {
          ...defaultConfirmationPropertiesOnLogOut(),
          open: true,
          title: i18n.t("translation:header.logoutFrom", { email: account && account.email }),
          primaryHandler: () => {
            this.logout();
            this.setState({ confirmationDialogProps: defaultConfirmationPropertiesOnLogOut() });
          },
          secondaryHandler: () => {
            this.setState({ confirmationDialogProps: defaultConfirmationPropertiesOnLogOut() });
          },
        },
      });
  }

  logout() {
    let { history, updateAcccount, t, fetchTasks } = this.props;

    if (history.location.pathname == DASHBOARD_PATH) {
      resetTasks();
    }
    fetchWrapper(this.logoutFunction)
      .then((res: any) => {
        if (res.status === 200) {
          updateAcccount(undefined);
          /* update rxjs store values */
          updateLogInBlocState(defaultLoginState);
          history.push("/login");
        }
      })
      .catch(() => {
        showNotification("error", t("common.serverErrorTryAgain"));
      });
  }

  renderToggleDrawerIcon(account: any) {
    const currentPath = this.props.location.pathname;
    const showToggleDrawerIcon = !(currentPath === url.login || currentPath === url.changePassword);
    if (account && showToggleDrawerIcon) {
      return (
        <>
          <button onClick={() => this.toggleDrawer()} style={headerStyle.menuStyle}>
            <HamburgerMenuIcon />
          </button>
          {!this.props.mobileView && <JuakaliIcon />}
        </>
      );
    }
    return undefined;
  }

  renderHeaderText(account: any) {
    if (account) {
      if (this.props.location.pathname === "/") {
        return (
          <Typography variant="h2" title={"title"} style={headerStyle.dashboardText as CSSProperties} className="dashboardTitle">
            {this.props.t("menu.dashboard")}
          </Typography>
        );
      } else if (this.props.location.pathname.startsWith("/objectdata")) {
        return (
          <Typography variant="h2" title={"title"} style={headerStyle.dashboardText as CSSProperties} className="dashboardTitle">
            {this.props.t("menu.reports")}
          </Typography>
        );
      }
    }
    return null;
  }

  renderActionButtons(account: any) {
    // eslint-disable-next-line react/prop-types
    const currentPath = this.props.location.pathname;
    if (currentPath === url.login || currentPath === url.changePassword) {
      return undefined;
    }
    if (account) {
      return (
        <div style={headerStyle.dividerWrapper}>
          <Notification history={this.props.history} />
          <Divider orientation="vertical" flexItem style={headerStyle.divider} />
          <LogoutView
            open={this.state.isLogoutPopupOpen}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
            handleClick={this.handleClick}
            mobileView={this.props.mobileView}
          />
        </div>
      );
    }
    return undefined;
  }

  renderLogo() {
    const currentPath = this.props.location.pathname;
    return (
      (currentPath === url.passwordReset || currentPath === url.changePassword) && (
        <Typography variant="h6">
          <JuakaliIcon />
        </Typography>
      )
    );
  }
  renderHeader(account: any) {
    const { headerStack } = headerPosition(this.props.mobileView, this.props.tabletView, this.props.menuOpenSm);
    return (
      <>
        <header style={headerStack as CSSProperties}>
          <div className="mdc-top-app-bar__row">
            <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
              {this.props.mobileView || this.props.tabletView || !this.props.menuOpen ? this.renderToggleDrawerIcon(account) : undefined}
              {this.renderHeaderText(account)}
              {this.renderLogo()}
            </section>
            <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-end">{this.renderActionButtons(account)}</section>
          </div>
        </header>
        <ConfirmationDialog {...this.state.confirmationDialogProps} />
      </>
    );
  }

  render() {
    return <AccountContext.Consumer>{account => this.renderHeader(account)}</AccountContext.Consumer>;
  }
}
function mapDispatchToProps(dispatch: any) {
  const actions = {
    resetTasks: () => dispatch(resetTasks()),
  };
  return actions;
}
export default withRouter(connect(
  null,
  mapDispatchToProps
)(withTranslation()(Header as any)) as any) as any;
