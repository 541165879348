// @flow
/* eslint-disable no-param-reassign */
import { createReducer, AnyAction, createAction, Action } from "redux-starter-kit";
import {
  OpenTaskState,
  defaultOpenTaskState,
  TypedAction,
  DownloadUserRequest,
  UsersResult,
  JuakaliUser,
  ReassignParams,
  ReassignResult,
} from "../types";
import { fetchAllUsers, fetchForQueryAndSortTasks, fetchForQueryAndSortTeamTasks, reassign } from "../../services/TaskService";
import { fetchWrapper } from "../../core/fetchWrapper";
import { TaskAssignment, TaskQueryParams, TaskStatus, TaskViewRepresentation, QueryParams } from "../../types/taskQueryTypes";
import { PagedApiResult, PagedResult } from "../../types/commonTypes";
import { checkIfFilterParamsPresent } from "../../views/Dashboard/utils/toolbarHelper";
import history from "../../core/history";

const actions = {
  TASKS_LOADING: "TASKS_LOADING",
  TASKS: "TASKS",
  ERROR: "ERROR",
  USERS: "USERS",
};

const getIncrementedDate = (dateTobeIncremented: string): string => {
  let dateFormat = new Date(dateTobeIncremented);
  let dateStr = new Date(dateFormat.setDate(dateFormat.getDate() + 1)).toISOString();
  return dateStr.substring(0, dateStr.lastIndexOf("T") + 0);
};

export interface SetTasksPayload {
  apiResult: PagedApiResult<TaskViewRepresentation> | PagedResult<TaskViewRepresentation>;
  taskQueryParams: TaskQueryParams;
  status: string;
}

export interface SetUsersPayload {
  apiResult: JuakaliUser[];
  status: string;
}

const setTasksLoading = createAction(actions.TASKS_LOADING);
const setTasks = createAction(actions.TASKS);
const setError = createAction(actions.ERROR);
const setUsers = createAction(actions.USERS);
const resetTask = createAction("resetTask");

const openTasksReducer = createReducer<OpenTaskState, AnyAction>(defaultOpenTaskState, {
  [setTasksLoading.type]: (state: OpenTaskState, action: AnyAction) => {
    if (action.payload.assignment === TaskAssignment.INVOLVED) {
      state.involvedTasksLoading = action.payload.loading;
    } else if (action.payload.assignment === TaskAssignment.CANDIDATE) {
      state.candidateTasksLoading = action.payload.loading;
    } else {
      state.teamTasksLoading = action.payload.loading;
    }
  },
  [setError.type]: (state: OpenTaskState, action: AnyAction) => {
    let { assignment, status } = action.payload;
    if (assignment === TaskAssignment.INVOLVED) {
      state.involvedTasksStatus = status;
    } else if (assignment === TaskAssignment.CANDIDATE) {
      state.candidateTasksStatus = status;
    } else {
      state.teamTasksStatus = status;
    }
  },
  [setTasks.type]: (state: OpenTaskState, action: TypedAction<SetTasksPayload>) => {
    if (action.payload) {
      let { apiResult, taskQueryParams, status } = action.payload;
      switch (action.payload.taskQueryParams.assignment) {
        case TaskAssignment.INVOLVED:
          state.involvedTasksApiResult = apiResult as PagedApiResult<TaskViewRepresentation>;
          state.assignedTaskQueryParams = { ...taskQueryParams };
          state.involvedTasksStatus = status;
          break;
        case TaskAssignment.CANDIDATE:
          state.candidateTasksApiResult = apiResult as PagedApiResult<TaskViewRepresentation>;
          state.candidateTaskQueryParams = { ...taskQueryParams };
          state.involvedTasksStatus = status;
          break;
        default:
          state.teamTasksApiResult = apiResult as PagedApiResult<TaskViewRepresentation>;
          state.teamTaskQueryParams = { ...taskQueryParams };
          state.teamTasksStatus = status;
      }
    }
    return state;
  },
  [setUsers.type]: (state: OpenTaskState, action: TypedAction<SetUsersPayload>) => {
    if (action.payload) {
      let { status, apiResult } = action.payload;
      state.teamTasksStatus = status;
      state.users = apiResult;
    }
    return state;
  },
  [resetTask.type]: (state: OpenTaskState, action: TypedAction<any>) => {
    state = action.payload;
    return state;
  },
});

export default openTasksReducer;

export function setStatus(assignment: TaskAssignment, status?: string): (dispatch: any) => void {
  return (dispatch: any) => {
    dispatch(setError({ assignment, status: status ? status : "" }));
  };
}

export function resetTasks() {
  return (dispatch: any) => {
    dispatch(resetTask(defaultOpenTaskState));
  };
}
export function fetchTasks(params: Partial<TaskQueryParams & QueryParams>): (dispatch: any) => void {
  return (dispatch: any) => {
    const currentParams = { ...params };
    let { filterParams, assignment, filterNew } = params;
    /* set the params for fetch api call with +1 date increment */
    currentParams.filterParams =
      filterParams && checkIfFilterParamsPresent(filterParams)
        ? {
            ...filterParams,
            dueDate: filterParams.dueDate && assignment && !filterNew ? getIncrementedDate(filterParams.dueDate) : filterParams.dueDate,
          }
        : undefined;
    dispatch(setTasksLoading({ ...params, loading: true }));
    var openUserTasksPromise = fetchWrapper(
      assignment === TaskAssignment.INVOLVED || assignment === TaskAssignment.CANDIDATE ? fetchForQueryAndSortTasks : fetchForQueryAndSortTeamTasks,
      currentParams
    );
    return openUserTasksPromise.then(
      function(response: any) {
        if (response.status === 200) {
          response.json().then((result: PagedApiResult<TaskViewRepresentation>) => {
            dispatch(setTasks({ apiResult: result, taskQueryParams: params, status: TaskStatus.SUCCESS }));
            dispatch(setTasksLoading({ ...currentParams, loading: false }));
          });
        } else {
          if (response.status !== 403 && response.status !== 401) {
            dispatch(setTasksLoading({ ...params, loading: false }));
            dispatch(setError({ ...params, status: TaskStatus.ERROR }));
            // showNotification("error", i18n.t("common.serverErrorTryAgain"));
          }
        }
      },
      function(errorResponse: any) {
        dispatch(setTasksLoading({ ...params, loading: false }));
        dispatch(setError({ ...params, status: TaskStatus.ERROR }));
        // showNotification("error", i18n.t("common.errorMessage", errorResponse ? "Unknown" : errorResponse.message));
      }
    );
  };
}

export function fetchUsers(params: DownloadUserRequest): (dispatch: any) => void {
  return (dispatch: any) => {
    dispatch(setTasksLoading({ ...params, loading: true }));
    var fetchUsersPromise = fetchWrapper(fetchAllUsers, params);
    return fetchUsersPromise.then(
      function(response: any) {
        if (response.status === 200) {
          response.json().then(({ result }: PagedApiResult<JuakaliUser>) => {
            dispatch(setUsers({ apiResult: result && result.content, status: TaskStatus.SUCCESS }));
            dispatch(setTasksLoading({ ...params, loading: false }));
          });
        } else {
          if (response.status !== 403 && response.status !== 401) {
            dispatch(setTasksLoading({ ...params, loading: false }));
            dispatch(setError({ ...params, status: TaskStatus.ERROR }));
            // showNotification("error", i18n.t("common.serverErrorTryAgain"));
          }
        }
      },
      function(errorResponse: any) {
        dispatch(setTasksLoading({ ...params, loading: false }));
        dispatch(setError({ ...params, status: TaskStatus.ERROR }));
        // showNotification("error", i18n.t("common.errorMessage", errorResponse ? "Unknown" : errorResponse.message));
      }
    );
  };
}

export function reassignTask(params: ReassignParams): (dispatch: any) => void {
  return (dispatch: any) => {
    dispatch(setTasksLoading({ ...params, loading: true }));
    dispatch(setError({ status: "" }));
    var reassignTaskPromise = fetchWrapper(reassign, params);
    return reassignTaskPromise.then(
      function(response: any) {
        if (response.status === 200) {
          response.json().then((result: ReassignResult) => {
            dispatch(setError({ ...params, status: `REASSIGN_${TaskStatus.SUCCESS}` }));
            dispatch(setTasksLoading({ ...params, loading: false }));
          });
        } else {
          if (response.status !== 403 && response.status !== 401) {
            dispatch(setTasksLoading({ ...params, loading: false }));
            dispatch(setError({ ...params, status: `REASSIGN_${TaskStatus.ERROR}` }));
            // showNotification("error", i18n.t("common.serverErrorTryAgain"));
          }
        }
      },
      function(errorResponse: any) {
        dispatch(setTasksLoading({ ...params, loading: false }));
        dispatch(setError({ ...params, status: `REASSIGN_${TaskStatus.ERROR}` }));
        // showNotification("error", i18n.t("common.errorMessage", errorResponse ? "Unknown" : errorResponse.message));
      }
    );
  };
}
